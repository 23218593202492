import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import PaymentFormHint from "./PaymentFormHint";
import "../assets/css/Stripe.css";

const PUBLIC_KEY =
  "pk_live_51LFh2OJsNwj9rybc0tvpLTAqLfEweHmbDAkv3YukrUIXrIxtXPd7tqBvGxSSZEA55r3KlH2V7AZETbQhuqwn4OqT00NdPDedFd";

const stripePromise = loadStripe(PUBLIC_KEY);

export default function StripeContainerHint() {
  const [clientSecret, setClientSecret] = useState("");
  const [trandsactionId, setTrandsactionId] = useState(0);
  // const [runOnce, setRunOnce] = useState(true);

  useEffect(async () => {
    const valueHint = window.sessionStorage.getItem("pph");

    if (valueHint == 0) {
    } else if (valueHint == 0.89) {
      const url = `${process.env.REACT_APP_URL}/api/hint5`;
      var resp = await axios.get(url);

      setClientSecret(resp.data.paymentIntent);
      setTrandsactionId(resp.data.customer);
    } else if (valueHint == 1.79) {
      const url = `${process.env.REACT_APP_URL}/api/hint15`;
      var resp = await axios.get(url);
      setClientSecret(resp.data.paymentIntent);
      setTrandsactionId(resp.data.customer);
    } else if (valueHint == 2.49) {
      const url = `${process.env.REACT_APP_URL}/api/hint30`;
      var resp = await axios.get(url);
      setClientSecret(resp.data.paymentIntent);
      setTrandsactionId(resp.data.customer);
    } else {
      const url = `${process.env.REACT_APP_URL}/api/hint60`;
      var resp = await axios.get(url);
      setClientSecret(resp.data.paymentIntent);
      setTrandsactionId(resp.data.customer);
    }
  }, []);

  const appearance = {
    theme: "stripe",

    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret == 0 ? null : (
        <div>
          <Elements stripe={stripePromise} options={options}>
            <PaymentFormHint csId={clientSecret} tId={trandsactionId} />
          </Elements>
        </div>
      )}
    </>
  );
}
