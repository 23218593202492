import React, { useState } from "react";
import { useEffect } from "react";
import Menubar from "./Menubar";
import Sharehook from "./sharehook";

function Recordpage() {
  const [user, setUser] = useState([]);

  useEffect(() => {
    const checknotification = `${process.env.REACT_APP_URL}/api/monthlyTopRank`;

    var myHeaders = new Headers();
    myHeaders.append(
      "APPKEY",
      "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
    );
    fetch(checknotification, {
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response.data, "jkjkj");
        setUser(response.data);
        // console.log(response.data, "neha");
      });
  }, []);
  // console.log(user, "pooja");

  return (
    <div>
      <Menubar />
      <div className="container">
        <h4 className="monthly">Monthly Winners</h4>

        <table className="table-user">
          <tr>
            {user.map((item, i) => (
              <>
                <h4 className="center-year">{item.yearMonthR}</h4>

                <table key={i} className="table-user">
                  <tr>
                    <th>Age</th>
                    {/* <th>Rank</th> */}
                    <th>Points</th>
                    <th>Username</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Country</th>
                  </tr>
                  <tr>
                    {item.under11.map((itemdata) => (
                      <>
                        <b>
                          <td>Under11</td>
                        </b>
                        {/* <td>{itemdata.rank}</td> */}
                        <td>
                        <b> {itemdata.total} pts</b>
                        </td>
                        <td>{itemdata.username}</td>
                        <td>{itemdata.city}</td>
                        <td>{itemdata.state}</td>
                        <td>{itemdata.country}</td>
                      </>
                    ))}
                  </tr>
                  <tr>
                    {/* <td>Under15:</td> */}

                    {item.under15.map((itemdata) => (
                      <>
                        <b>
                          <td>Under15</td>
                        </b>
                        {/* <td>{itemdata.rank}</td> */}
                        <td>
                        <b> {itemdata.total}
                          pts</b>
                        </td>
                        <td>{itemdata.username}</td>
                        <td>{itemdata.city}</td>
                        <td>{itemdata.state}</td>
                        <td>{itemdata.country}</td>
                      </>
                    ))}
                  </tr>
                  <tr>
                    {item.under18.map((itemdata) => (
                      <>
                        <b>
                          <td>Under18</td>
                        </b>
                        {/* <td>{itemdata.rank}</td> */}
                        <td>
                        <b> {itemdata.total} pts</b>
                        </td>
                        <td>{itemdata.username}</td>
                        <td>{itemdata.city}</td>
                        <td>{itemdata.state}</td>
                        <td>{itemdata.country}</td>
                      </>
                    ))}
                  </tr>

                  <tr>
                    {item.allAge.map((itemdata) => (
                      <>
                        <b>
                          {" "}
                          <td>All Ages</td>
                        </b>
                        {/* <td>{itemdata.rank}</td> */}
                        <td>
                        <b> {itemdata.total} pts</b>
                        </td>
                        <td>{itemdata.username}</td>
                        <td>{itemdata.city}</td>
                        <td>{itemdata.state}</td>

                        <td>{itemdata.country}</td>
                      </>
                    ))}
                  </tr>
                </table>
              </>
            ))}
          </tr>
        </table>
      </div>
      <p className="user-share">
        <Sharehook />
      </p>
    </div>
  );
  
}
export default Recordpage;
