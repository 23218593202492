// import getCourseApi from "./Api.js";

// import CardSection from "./CardSection";

import {
  Elements,
  CardElement,
  useElements,
  useStripe,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import axios from "axios";
const stripePromise = loadStripe(
  "pk_live_51LFh2OJsNwj9rybc0tvpLTAqLfEweHmbDAkv3YukrUIXrIxtXPd7tqBvGxSSZEA55r3KlH2V7AZETbQhuqwn4OqT00NdPDedFd"
);
console.log(stripePromise, "PPPPPPPPP");

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [success, setSuccess] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      try {
        const { id } = paymentMethod;
        console.log(paymentMethod, "jjbbbbbbbbbbbb");
        console.log(id, "kkkkkhhhhhhhhhhhkk");
        const response = await axios.get(
          `https://admin.ne-lite.com/api/make/paymentpost?id=` + id
        );
        console.log(response, "hhhhhhh");
        if (response.data.success) {
          console.log("Success payment");
          setSuccess(true);
        }
      } catch (error) {
        console.log("Error", error);
      }
    } else {
      console.log(error.message);
    }
  };
  return (
    <>
      {!success ? (
        <div>
          <form onSubmit={handleSubmit}>
            {/* <h1> stripe payment</h1> */}
            <div className="payment-stripe a">
              <CardElement />
            </div>

            <br />
            <button>Pay</button>
          </form>
        </div>
      ) : (
        <div>
          <h2>payment successfully</h2>
        </div>
      )}
    </>
  );
};

const StripePaymentForm = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);
export default StripePaymentForm;
