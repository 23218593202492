
import React from "react";
import html2canvas from "html2canvas";
export default function Sharehook() {
  async function screenshot() {
    const screenshotTarget = document.body;

    html2canvas(screenshotTarget).then(async (canvas) => {
      const base64image = canvas.toDataURL();

      const response = await fetch(base64image);
      const blob = await response.blob();
      const filesArray = [
        new File([blob], "meme.jpg", {
          type: "image/jpeg",
          lastModified: new Date().getTime()
        })
      ];
      const shareData = {
        files: filesArray
      };
      navigator.share({
        files: filesArray,
        title:"\n\nI am playing this amazing word game against artificial intelligence called The Never Ending Game (NE-Lite).\n\nYou will not have played any other word game like it. \n\nI know you'll love it. \n\nDownload it and compete with me.  We score points when we win and we can see each other's points on a wonderful ranking system which ranks us by age (Under 11, Under 15, Under 18 and All Ages) and also by location, locally by county/state/province, nationally by country and internationally - worldwide. \n\n I look forward to seeing you on there. \n\nDownload it from:\n GOOGLE : https://play.google.com/store/apps/details?id=com.nelitegame.nelitegame\n APPLE : https://apps.apple.com/us/app/apple-store/id1629862247\n WEB-BASED : https://ne-lite.com/",
        
      }); 
    });
  }
  return (
    <>
      <div >
        <button type="button" className="sherebox sherebtn" onClick={screenshot}> <span className="marshar">Share</span> <span class="share-right"><i class="fas fa-share-square"></i></span></button>
        
      </div>
    </>
  );
}
