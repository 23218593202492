
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Menubar from './Menubar';
import Sound from './Sound';



export default function ForGroups(props) {
const [gameinfo,setgameinfostate] = useState(false)
const history = useHistory();



const [settingshow, setsettingshow] = useState(props.setting)
useEffect(() => {
  let bodyElement = document.getElementsByTagName('body')[0];
  bodyElement.className = "Dashbord";
})
 
 useEffect(() => {

  // window.location.reload();
  // window.location.reload();
  // window.open("/Paymentplan","_self")
   

   history.push("/Paymentplan")
   


  }, []);
               
  
  
  return(
     <>
   <Menubar setpop={setsettingshow} audioRef={props.audio} />
  
  
  
  

    </> 
  )
    

  


}