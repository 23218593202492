import { getSpaceUntilMaxLength } from "@testing-library/user-event/dist/utils";
import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import BackButton from "./BackButton";
import axios from "axios";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { string } from "yup";

//  import DatePicker from 'react-datepicker'

//import DatePicker from "react-datepicker";

export default function NewRegister() {
  const dateref = useRef();
  const [loader, setloader] = useState(false);
  const [confirmpassword, setConfirmPassword] = useState("");
  const [name, setname] = useState("");
  const [username, setusername] = useState("");
  const [referralcode, setreferralcode] = useState("");
  const [dob, setdob] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);
  const [email, setemail] = useState("");
  const [color, setcolor] = useState("coral");
  const [color1, setcolor1] = useState("");
  const [error, seterror] = useState("");
  const [popshow, setpopshow] = useState(false);
  const [catcherror, setcatcherror] = useState(false);
  const [password, setPassword] = useState("");
  // const [selectDate, setSelectedDate] = useState(null)
  const [selectDate, setSelectedDate] = React.useState(null);

  // const [success, setSuccess] = React.useState(false);

  // const [Email, setEmail] = useState();
  const [otp1, setOtp1] = useState();
  const [otp2, setOtp2] = useState();
  const [otp3, setOtp3] = useState();
  const [otp4, setOtp4] = useState();
  const [disabled, setDisabled] = useState(true);
  const [disabledButton, setDisabledButton] = useState(true);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [inputDisabled, setInputDisabled] = useState(false);
  const [status, setStatus] = useState();

  useEffect(() => {
    let bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.className = "Dashbord";
  });
  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleconfirmPasswordVisibility2 = () => {
    setConfirmPasswordShown(confirmpasswordShown ? false : true);
  };

  const allowonlyletus = () => {
    if ((name > 64 && name < 91) || (name > 96 && name < 123)) {
      return true;
    }
  };
  const validateEmail = (email) => {
    var regex =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; // /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };
  const enddate = new Date(
    new Date().getFullYear() - 7,
    new Date().getMonth(),
    new Date().getDate()
  );

  useEffect(() => {
    var value = document.getElementById("myDate").value;
    console.log(value, "ooooooooooo");

    setdob(value);
  });
  const validatePassword = (password) => {
    var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,15}$/; ///^(?=.*[a-z])(?=.*[A-Z]).{5,15}$/;
    return regex.test(String(password).toLowerCase());
  };
  const validateReferral = (referralcode) => {
    var regex = /^[0-9]{1,6}$/;
    return regex.test(String(referralcode).toLowerCase());
  };
  const validateName = (name) => {
    var regex = /^[a-zA-Z][a-zA-Z ]{2,}$/;
    return regex.test(String(name).toLowerCase());
  };
  const validateDob = (dob) => {
    var regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return regex.test(String(dob).toLowerCase());
  };
  const validateDate = (dob) => {
    var date_regex =
      "^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$";
    return date_regex.test(String(dob));
  };
  // const dateRegex = new RegExp('/^([0-2^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/ ')
  //const dobr = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
  const disableDates = () => {
    var today, mm, dd, yyyy;
    today = new Date();
    mm = today.getData() + 1;
    dd = today.getData() + 1;
    yyyy = today.getFullYear();
    return yyyy + "_" + mm + "_" + dd;
  };

  // submit and verify function call
  function register(e) {
    var finalOtp = otp.join("");
    //  alert("Entered OTP is " + otp.join(""));
    // var finalOtp = handlechange;
    // alert(finalOtp);
    axios
      .post(
        "https://admin.ne-lite.com/api/user/verifyOtpweb",
        {
          email: email,
          otp: finalOtp,
          register_type: "email",
        },
        {
          headers: {
            APPKEY:
              "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy",
          },
        }
      )

      .then((res) => {
        console.log(res.data.status);
        // setStatus(res.data.status);
        if (res.data.status === 200) {
          // alert("Entered OTP is " + otp.join(""));
          // alert("OTP successful!");
          axios
            .post(
              "https://admin.ne-lite.com/api/user/registerWeb",
              {
                username: username,
                name: name,
                register_type: "email",
                password: password,
                email: email,
                device_type: "web",
              },
              {
                headers: {
                  APPKEY:
                    "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy",
                },
              }
            )
            .then((res) => {
              console.log(res.data.success_message);
            })
            .catch((error) => {
              alert(error.data.error_message);
              console.log(error.data.error_message);
              setDisabled(true);
              setInputDisabled(true);
            });

          console.log(referralcode);
          e.preventDefault();
          // setSelectedDate(true)
          // var date_regex =  "^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$"
          // let  date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

          if (name === "") {
            seterror(" Full Name is required");
            setpopshow(true);
            setcolor1("#C70100");
          } else if (name.length > 25) {
            seterror(" Full Name is Not Valid");
            setpopshow(true);
            setcolor1("#C70100");
          } else if (username === "") {
            seterror("Username is required");
            setpopshow(true);
            setcolor1("#C70100");
          } else if (username.length > 25) {
            seterror("Username is Not Valid");
            setpopshow(true);
            setcolor1("#C70100");
          } else if (dob === "") {
            seterror("Date of Birth is required");
            setpopshow(true);
            setcolor1("#C70100");
          } else if (referralcode !== "" && referralcode.length < 6) {
            seterror("Please enter minimum 6 length referral code");
            setpopshow(true);
            setcolor1("#C70100");
          } else if (email === "") {
            seterror("Email is required");
            setpopshow(true);
            setcolor1("#C70100");
          } else if (email !== "" && !validateEmail(email)) {
            seterror("Email is Not Valid");
            setpopshow(true);
            setcolor1("#C70100");
          } else if (password === "") {
            seterror("Password is required");
            setpopshow(true);
            setcolor1("#C70100");
          } else if (password !== "" && password.length < 5) {
            seterror("Please enter minimum 5 length password");
            setpopshow(true);
            setcolor1("#C70100");
          }
          // else if (password !== "" && !validatePassword(password)) {
          //   seterror("Password is Not Valid")
          //   setpopshow(true)
          //   setcolor1("#C70100")
          // }
          else if (confirmpassword === "") {
            seterror("Confirm password is required");
            setpopshow(true);
            setcolor1("#C70100");
          } else if (password !== confirmpassword) {
            seterror("Password Not Matched");
            setpopshow(true);
            setcolor1("#C70100");
          } else if (password.length < 5 || password.length > 16) {
            seterror("Password is Not Valid");
            setpopshow(true);
            setcolor1("#C70100");
          }
          // else if (otp !== finalOtp) {
          //   seterror("OTP is Not Valid");
          //   setpopshow(true);
          //   setcolor1("#C70100");
          // }
          else {
            // alert("OTP successful!")
            setloader(true);
            var myHeaders = new Headers();
            myHeaders.append(
              "APPKEY",
              "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
            );

            var formdata = new FormData();
            formdata.append("name", name);
            formdata.append("username", username);
            formdata.append("register_type", "email");
            formdata.append("email", email);
            formdata.append("dob", "" + dob);
            formdata.append("device_type", "web");
            formdata.append("referralcode", referralcode);
            //  formdata.append("dob", dob)
            formdata.append("password", password);

            const url = `${process.env.REACT_APP_URL}/api/user/registerWeb`;

            fetch(url, {
              method: "POST",
              headers: myHeaders,
              body: formdata,
              redirect: "follow",
            })
              .then((response) => response.json())
              .then(async (response) => {
                if (response.status === 200 && response.message === "Success") {
                  setloader(false);
                  const url3 = `${process.env.REACT_APP_URL}/api/buyHints`;

                  var myHeaders = new Headers();
                  myHeaders.append(
                    "APPKEY",
                    "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
                  );

                  var formdata = new FormData();
                  formdata.append("user_id", response.data.id);
                  formdata.append("count", "5");
                  formdata.append("amount", "0000");
                  formdata.append("transaction_id", "0000");

                  await fetch(url3, {
                    method: "POST",
                    headers: myHeaders,
                    body: formdata,
                    redirect: "follow",
                  })
                    .then((resp) => resp.json())
                    .then(async (resp) => {
                      if (resp.status === 200 && resp.message === "Success") {
                      }
                    });
                  seterror(response.success_message);
                  setpopshow(true);
                  setcolor1("#00c767");
                } else {
                  console.log(response);
                  setloader(false);
                  if (response.error_message.email === undefined) {
                    seterror(response.error_message.username[0]);
                    setpopshow(true);
                    setcolor1("#C70100");
                  } else if (response.error_message.username === undefined) {
                    seterror(response.error_message.email[0]);
                    setpopshow(true);
                    setcolor("#C70100");
                  } else {
                    seterror(response.error_message.username[0]);
                    setpopshow(true);
                    setcolor1("#C70100");
                  }
                }
              })
              .catch((err) => {
                setcatcherror(true);

                seterror("Please check all Fields!");

                setcolor1("#C70100");
              });
          }
        } else {
          console.log(res.data.status);
          // alert("INVALID OTP");
          seterror("INVALID OTP!");
          setpopshow(true);
          setcolor1("#C70100");
        }
      })
      .catch((error) => {
        alert(error.message);
        console.log(error.message);
      });

    // if (otp !== finalOtp) {
    //   seterror("Invalid OTP!");
    //   setpopshow(true);
    //   setcolor1("#C70100");
    // }
    // register api call

    // alert("Verification is done Successfully!");
  }

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        document.getElementById("submit-register").click();
      }
    });
  }, []);

  const sendOtp = () => {
    if (name === "") {
      seterror(" Full Name is required");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    } else if (name.length > 25) {
      seterror(" Full Name is Not Valid");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    } else if (username === "") {
      seterror("Username is required");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    } else if (username.length > 25) {
      seterror("Username is Not Valid");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    } else if (dob === "") {
      seterror("Date of Birth is required");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    } else if (referralcode !== "" && referralcode.length < 6) {
      seterror("Please enter minimum 6 length referral code");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    } else if (email === "") {
      seterror("Email is required");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    } else if (email !== "" && !validateEmail(email)) {
      seterror("Email is Not Valid");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    } else if (email === "" && validateEmail(email)) {
      seterror("already exist");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    } else if (password === "") {
      seterror("Password is required");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    } else if (password !== "" && password.length < 5) {
      seterror("Please enter minimum 5 length password");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    }
    // else if (password !== "" && !validatePassword(password)) {
    //   seterror("Password is Not Valid")
    //   setpopshow(true)
    //   setcolor1("#C70100")
    // }
    else if (confirmpassword === "") {
      seterror("Confirm password is required");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    } else if (password !== confirmpassword) {
      seterror("Password Not Matched");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    } else if (password.length < 5 || password.length > 16) {
      seterror("Password is Not Valid");
      setpopshow(true);
      setcolor1("#C70100");
      setInputDisabled(false);
    } else {
      // setloader(true);
      var headers = new Headers();

      axios
        .post(
          "https://admin.ne-lite.com/api/user/sendOtpWeb",
          {
            email: email,
            // id: "5554",
            register_type: email,
            username: username,
          },
          {
            headers: {
              APPKEY:
                "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy",
            },
          }
        )

        .then((res) => {
          console.log(res.data.message);
          
          // setDisabled(false);
          // alert(res.data.message);
          if (res.data.status === 200) {
            setDisabled(false);
            setInputDisabled(false);
            alert(res.data.message);
            setInputDisabled(true);
            setSendDisabled(true);
           
          } else {
            alert(res.data.error_message);
            setDisabled(true);
            setInputDisabled(true);
            setInputDisabled(false);
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
      setDisabled(true);
      setInputDisabled(true);
      
    }
  };

  const handleChange = (e) => {
    setemail(e.target.value);
    setSendDisabled(false);
  };

  // const getOtp1 = (e) => {
  //   setOtp1(e.target.value);
  // };
  // const getOtp2 = (e) => {
  //   setOtp2(e.target.value);
  // };
  // const getOtp3 = (e) => {
  //   setOtp3(e.target.value);
  // };
  // const getOtp4 = (e) => {
  //   setOtp4(e.target.value);
  //   setDisabledButton(false);
  // };

  // resend otp code
  // const resendOtp = () => {
  //   setOtp1("");
  //   setOtp2("");
  //   setOtp3("");
  //   setOtp4("");

  //   axios
  //     .post(
  //       "https://admin.ne-lite.com/api/user/sendOtp",
  //       {
  //         email: email,
  //         id: "5554",
  //         register_type: "email",
  //       },
  //       {
  //         headers: {
  //           APPKEY:
  //             "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy",
  //         },
  //       }
  //     )

  //     .then((res) => {
  //       console.log(res.data.message);
  //       setDisabled(false);
  //       alert(res.data.message);
  //     });
  // };

  const handlechange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    // focus next input
    // if (element.nextSibling) {
    //   element.nextSibling.focus();
    //   setDisabledButton(false);
    // }
    setDisabledButton(false);
   
   
  };

  const resendOtp = () => {
    setOtp([...otp.map((v) => "")]);
    if (name === "") {
      seterror("Full Name is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (name.length > 25) {
      seterror("Full Name is Not Valid");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (username === "") {
      seterror("Username is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (username.length > 25) {
      seterror("Username is Not Valid");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (dob === "") {
      seterror("Date of Birth is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (referralcode !== "" && referralcode.length < 6) {
      seterror("Please enter minimum 6 length referral code");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (email === "") {
      seterror("Email is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (email !== "" && !validateEmail(email)) {
      seterror("Email is Not Valid");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (password === "") {
      seterror("Password is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (password !== "" && password.length < 5) {
      seterror("Please enter minimum 5 length password");
      setpopshow(true);
      setcolor1("#C70100");
    }
    // else if (password !== "" && !validatePassword(password)) {
    //   seterror("Password is Not Valid")
    //   setpopshow(true)
    //   setcolor1("#C70100")
    // }
    else if (confirmpassword === "") {
      seterror("Confirm password is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (password !== confirmpassword) {
      seterror("Password Not Matched");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (password.length < 5 || password.length > 16) {
      seterror("Password is Not Valid");
      setpopshow(true);
      setcolor1("#C70100");
    } else {
      // setloader(true);
      var headers = new Headers();

      axios
        .post(
          "https://admin.ne-lite.com/api/user/sendOtpWeb",
          {
            email: email,
            username: username,
            // id: "5554",
            register_type: email,
          },
          {
            headers: {
              APPKEY:
                "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy",
            },
          }
        )

        .then((res) => {
          console.log(res.data.message);
          setDisabled(false);
          // alert(res.data.message);
          alert("OTP Resent Successfully!");
        })
        .catch((error) => {
          console.log(error.message);
          // alert(error.message);
        });
    }
    setDisabled(true);
    setInputDisabled(true);
  };

  // backspace delete code
  // const inputfocus = (element,index) => {
  //   if (element.index === "Delete" || element.index === "Backspace") {
  //     element.previousSibling.focus()
  //     const previous = element.target.tabIndex - 2;
  //     if (previous > -1) {
  //       element.target.form.elements[previous].focus();
  //     }
  //   } else {
  //     console.log("previous");
  //     const previous = element.target.tabIndex;
  //     if (previous < index) {
  //       element.target.form.elements[previous].focus();
  //     }
  //   }
  // };

  return (
    <div className="Newbannerbox">
      <BackButton />
      {loader ? (
        <div className="loader-wrapper1" id="loaderbox">
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      {catcherror ? (
        <>
          <div className="modalmaindiv" id="welcomeDiv">
            <div className="modaldiv">
              <div className=" cardbox " style={{ backgroundColor: color1 }}>
                <div className="eorriconbox">
                  <i className="fa fa-exclamation-triangle"></i>
                </div>

                <br />
                <p className="text-center text-white">{error}</p>
                <br />
                <div className="mainerrorclosebtn">
                  <div
                    className="errorclosebtn "
                    onClick={() => {
                      setpopshow(false);
                      window.location.reload();
                    }}
                  >
                    <p>OK</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="login-bg-design">
            <div className="container-fluid">
              <div className="formbox">
                <div className="formboxs" style={{ margin: "10px" }}>
                  <p
                    className="register-font marb10pr"
                    style={{ display: "inline" }}
                  >
                    <b>Register</b>
                  </p>
                  &nbsp;
                  <p
                    className="register-font marb10pr"
                    style={{ display: "inline", fontSize: "15.4px" }}
                  >
                    <b>
                      (You need to register so we can allocate you your points)
                    </b>
                  </p>
                  <form className="centerbox">
                    <div className="row mart30pr">
                      <div className="col-md-6 ">
                        <div className="texinput">
                          <input
                            disabled={inputDisabled}
                            type="text"
                            name="name"
                            placeholder="Full name*"
                            value={name}
                            onChange={(e) => {
                              setname(
                                e.target.value.length < 26
                                  ? e.target.value
                                  : name
                              );
                            }}
                            onKeyPress={(event) => {
                              if (!/[a-zA-Z ]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <span className="starbox">
                            {/*  <i className="fas fa-star"></i>*/}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* <input type="text" name="username" placeholder='User Name' max="5" value={username.toLowerCase()} onChange={(e) => { setusername(e.target.value.length <26 ? e.target.value : username) }} /><br /> */}
                        <input
                          disabled={inputDisabled}
                          type="text"
                          name="username"
                          placeholder="Username*"
                          value={username.toLowerCase()}
                          onChange={(e) => {
                            setusername(
                              e.target.value.length < 26
                                ? e.target.value
                                : username
                            );
                          }}
                          onKeyPress={(event) => {
                            if (/[, ,]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <span className="starbox">
                          {/*<i className="fas fa-star"></i>*/}
                        </span>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-6">
                        <div className="texinput">
                          {/* <input  type="text" ref={dateref} name="date" onFocus={() => (dateref.current.type = "date")} placeholder='D.O.B' value={dob} onChange={(e) => { setdob(e.target.value.length < 2015 ? e.target.value : dob)  }} /> */}

                          {/* <DatePicker 
                                            
                                            id="myDate"
                                            placeholderText="Date of birth*"
                                            selected={selectDate} 
                                            onChange={date => setSelectedDate(date)}
                                            dateFormat='yyyy-MM-dd'
                                            maxDate={enddate}
                                            /> */}
                          <DatePicker
                            disabled={inputDisabled}
                            id="myDate"
                            max={enddate}
                            format="yyyy-MM-dd"
                            onChange={(date) => setSelectedDate(date)}
                            placeholder="Date of birth*"
                          />

                          {/* <span className='starbox' ><i className="fas fa-star"></i></span> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <input
                          disabled={inputDisabled}
                          type="text"
                          name="code"
                          placeholder="Referral code"
                          value={referralcode}
                          onChange={(e) => {
                            setreferralcode(
                              e.target.value.length < 7
                                ? e.target.value
                                : referralcode
                            );
                          }}
                          onKeyPress={(event) => {
                            if (!/[a-zA-Z0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="texinput textinput">
                          <input
                            disabled={inputDisabled}
                            value={email}
                            id="email"
                            type="email"
                            name="email"
                            placeholder="Email*"
                            onChange={handleChange}
                            onKeyPress={(event) => {
                              if (!/[a-zA-Z0-9@.]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />

                          <span className="starbox">
                            <Button
                              disabled={sendDisabled}
                              className="btnbox text-center mb-3"
                              style={{ backgroundColor: color }}
                              id="send-otp"
                              onClick={sendOtp}
                            >
                              GET OTP
                            </Button>
                            {/* <i className="fas fa-star"></i>*/}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="texinput">
                          <input
                            disabled={inputDisabled}
                            className="pass-p-absolute"
                            type={passwordShown ? "text" : "password"}
                            name="password"
                            placeholder="Password*"
                            value={password}
                            onChange={(e) => {
                              setPassword(
                                e.target.value.length < 16
                                  ? e.target.value
                                  : password
                              );
                            }}
                          />
                          <i
                            className="far fa-eye field-icon"
                            style={{
                              color: "white",
                              position: "absolute",
                              right: "0rem",
                              cursor: "pointer",
                            }}
                            id="togglePassword"
                            onClick={togglePasswordVisibility}
                          ></i>
                          <span className="starbox martpass7p"></span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="texinput">
                          <input
                            disabled={inputDisabled}
                            className="pass-p-absolute"
                            type={confirmpasswordShown ? "text" : "password"}
                            name="password"
                            placeholder="Confirm password*"
                            value={confirmpassword}
                            onChange={(e) => {
                              setConfirmPassword(
                                e.target.value.length < 16
                                  ? e.target.value
                                  : confirmpassword
                              );
                            }}
                          />
                          <i
                            className="far fa-eye field-icon"
                            style={{
                              color: "white",
                              right: "0rem",
                              top: "26px",
                              cursor: "pointer",
                            }}
                            id="togglePassword"
                            onClick={toggleconfirmPasswordVisibility2}
                          ></i>

                          <span className="starbox"></span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row">
                  <div className="col-md-12">
                  <textarea type="text " name="bio" placeholder='Bio' value={bio} onChange={(e)=>{setbio(e.target.value)}}></textarea>

                  </div>
                
                </div> */}

                    {/* get otp field*/}
                    <div className="otp-section">
                      <div className="row otp-style">
                        {otp.map((data, index) => {
                          return (
                            <input
                              type="text"
                              className="otp-field"
                              name="otp"
                              onChange={(e) => handlechange(e.target, index)}
                              maxLength={1}
                              key={index}
                              value={data}
                              disabled={disabled}
                              onFocus={(e) => e.target.select()}
                              tabIndex={index}
                            />
                          );
                        })}
                      </div>
                      <p>
                        Didn't receive an OTP?
                        <Button
                          className="resend-btn"
                          disabled={disabled}
                          onClick={resendOtp}
                          style={{
                            background: "none",
                            border: "none",
                            color: "black",
                          }}
                        >
                          RESEND OTP
                        </Button>
                      </p>
                    </div>

                    <div className="row">
                      <div className="centered regis-btn-mart40p">
                        <Button
                          onClick={register}
                          disabled={disabledButton}
                          className="btnbox text-center mb-3"
                          style={{ backgroundColor: color }}
                          id="submit-register"
                        >
                          Verify & Submit
                        </Button>
                      </div>
                    </div>
                  </form>
                  <p className="text-center">
                    Already have an account?
                    <Link to="/">
                      <b style={{ color: "black" }}> login</b>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {popshow ? (
            <>
              <div className="modalmaindiv" id="welcomeDiv">
                <div className="modaldiv">
                  <div
                    className=" cardbox "
                    style={{ backgroundColor: color1 }}
                  >
                    {color1 === "#C70100" ? (
                      <>
                        <div className="eorriconbox">
                          <i className="fa fa-exclamation-triangle"></i>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="eorriconbox">
                          <i className="fa fa-badge-check"></i>
                        </div>
                      </>
                    )}

                    <p className="text-center text-white">{error}</p>

                    <div className="mainerrorclosebtn">
                      {color1 === "#C70100" ? (
                        <>
                          <div
                            className="errorclosebtn "
                            id="close-pop"
                            onClick={() => {
                              setpopshow(false);
                            }}
                          >
                            <p>OK</p>
                          </div>
                        </>
                      ) : (
                        <Link to="/" style={{ textDecoration: "none" }}>
                          <div
                            className="errorclosebtn1 "
                            onClick={() => {
                              setpopshow(false);
                            }}
                          >
                            <p>OK</p>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}
