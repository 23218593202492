import { getSpaceUntilMaxLength } from "@testing-library/user-event/dist/utils";
import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
// import BackButton from "./BackButton";

import BackButton from "../frontComponent/BackButton";

import { DatePicker } from "@progress/kendo-react-dateinputs";
import { string } from "yup";
//  import DatePicker from 'react-datepicker'

//import DatePicker from "react-datepicker";

export default function NewRegisterGuest() {
  const dateref = useRef();
  const [loader, setloader] = useState(false);
  const [confirmpassword, setConfirmPassword] = useState("");
  const [name, setname] = useState("");
  const [username, setusername] = useState("");
  const [referralcode, setreferralcode] = useState("");
  const [dob, setdob] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);
  const [email, setemail] = useState("");
  const [color, setcolor] = useState("coral");
  const [color1, setcolor1] = useState("");
  const [error, seterror] = useState("");
  const [popshow, setpopshow] = useState(false);
  const [catcherror, setcatcherror] = useState(false);
  const [password, setPassword] = useState("");
  // const [selectDate, setSelectedDate] = useState(null)
  const [selectDate, setSelectedDate] = React.useState(null);

  // const [success, setSuccess] = React.useState(false);

  useEffect(() => {
    let bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.className = "Dashbord";
  });
  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleconfirmPasswordVisibility2 = () => {
    setConfirmPasswordShown(confirmpasswordShown ? false : true);
  };

  const allowonlyletus = () => {
    if ((name > 64 && name < 91) || (name > 96 && name < 123)) {
      return true;
    }
  };
  const validateEmail = (email) => {
    var regex =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; // /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };
  const enddate = new Date(
    new Date().getFullYear() - 7,
    new Date().getMonth(),
    new Date().getDate()
  );

  useEffect(() => {
    // var value = document.getElementById("myDate").value;
    // console.log(value, "ooooooooooo");
    // setdob(value);
  });
  const validatePassword = (password) => {
    var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,15}$/; ///^(?=.*[a-z])(?=.*[A-Z]).{5,15}$/;
    return regex.test(String(password).toLowerCase());
  };
  const validateReferral = (referralcode) => {
    var regex = /^[0-9]{1,6}$/;
    return regex.test(String(referralcode).toLowerCase());
  };
  const validateName = (name) => {
    var regex = /^[a-zA-Z][a-zA-Z ]{2,}$/;
    return regex.test(String(name).toLowerCase());
  };
  const validateDob = (dob) => {
    var regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return regex.test(String(dob).toLowerCase());
  };
  const validateDate = (dob) => {
    var date_regex =
      "^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$";
    return date_regex.test(String(dob));
  };
  // const dateRegex = new RegExp('/^([0-2^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/ ')
  //const dobr = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
  const disableDates = () => {
    var today, mm, dd, yyyy;
    today = new Date();
    mm = today.getData() + 1;
    dd = today.getData() + 1;
    yyyy = today.getFullYear();
    return yyyy + "_" + mm + "_" + dd;
  };
  async function register(e) {
    console.log(referralcode, "kjkjk");
    e.preventDefault();

    if (username === "") {
      seterror("Guest User name is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (username.length > 25) {
      seterror("Guest Username is Not Valid");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (referralcode !== "" && referralcode.length < 6) {
      seterror("Please enter minimum 6 length referral code");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (password === "") {
      seterror("Password is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (password !== "" && password.length < 5) {
      seterror("Please enter minimum 5 length password");
      setpopshow(true);
      setcolor1("#C70100");
    }
    // else if (password !== "" && !validatePassword(password)) {
    //   seterror("Password is Not Valid")
    //   setpopshow(true)
    //   setcolor1("#C70100")
    // }
    else if (confirmpassword === "") {
      seterror("Confirm password is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (password !== confirmpassword) {
      seterror("Password Not Matched");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (password.length < 5 || password.length > 16) {
      seterror("Password is Not Valid");
      setpopshow(true);
      setcolor1("#C70100");
    } else {
      setloader(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "APPKEY",
        "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
      );

      var formdata = new FormData();
      formdata.append("name", name);
      formdata.append("username", username);
      // formdata.append("register_type", "email");
      // formdata.append("email", email);
      //  / formdata.append("dob", "" + dob);
      formdata.append("device_type", "web");
      formdata.append("register_type", "guest");
      formdata.append("referralcode", referralcode);
      //  formdata.append("dob", dob)
      formdata.append("password", password);

      const url = `${process.env.REACT_APP_URL}/api/guestUser/register`;

      await fetch(url, {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      })
        .then((response) => response.json())
        .then(async (response) => {
          console.log(response, "response ji hhh");
          if (response.status === 200 && response.message === "Success") {
            setloader(false);
            const url3 = `${process.env.REACT_APP_URL}/api/buyHints`;

            var myHeaders = new Headers();
            myHeaders.append(
              "APPKEY",
              "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
            );

            var formdata = new FormData();
            formdata.append("user_id", response.data.id);
            formdata.append("count", "5");
            formdata.append("amount", "0000");
            formdata.append("transaction_id", "0000");

            await fetch(url3, {
              method: "POST",
              headers: myHeaders,
              body: formdata,
              redirect: "follow",
            })
              .then((resp) => resp.json())
              .then(async (resp) => {
                if (resp.status === 200 && resp.message === "Success") {
                }
              });
            seterror(response.success_message);
            setpopshow(true);
            setcolor1("#00c767");
          } else {
            console.log(response);
            setloader(false);
            if (response.error_message.email === undefined) {
              seterror(response.error_message.username[0]);
              setpopshow(true);
              setcolor1("#C70100");
            } else if (response.error_message.username === undefined) {
              seterror(response.error_message.email[0]);
              setpopshow(true);
              setcolor("#C70100");
            } else {
              seterror(response.error_message.username[0]);
              setpopshow(true);
              setcolor1("#C70100");
            }
          }
        })
        .catch((err) => {
          // setcatcherror(true);
          window.open("/Guest", "_self");
          // seterror("Please check all Field");

          // setcolor1("#C70100");
        });
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        document.getElementById("submit-register").click();
      }
    });
  }, []);
  const sendOtp=()=>{

  }
  return (
    <div className="Newbannerbox">
      <BackButton />
      {loader ? (
        <div className="loader-wrapper1" id="loaderbox">
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      {catcherror ? (
        <>
          <div className="modalmaindiv" id="welcomeDiv">
            <div className="modaldiv">
              <div className=" cardbox " style={{ backgroundColor: color1 }}>
                <div className="eorriconbox">
                  <i className="fa fa-exclamation-triangle"></i>
                </div>

                <br />
                <p className="text-center text-white">{error}</p>
                <br />
                <div className="mainerrorclosebtn">
                  <div
                    className="errorclosebtn "
                    onClick={() => {
                      setpopshow(false);
                      window.location.reload();
                    }}
                  >
                    <p>OK</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="login-bg-design">
            <div className="container-fluid">
              <div className="formbox">
                <div className="formboxs" style={{ margin: "10px" }}>
                  <p
                    class="register-font marb10pr"
                    style={{ display: "inline" }}
                  >
                    <b>Register</b>
                  </p>
                  &nbsp;
                  <p
                    class="register-font marb10pr"
                    style={{ display: "inline", fontSize: "15.4px" }}
                  >
                    <b>
                      (You need to register so we can allocate you your points)
                    </b>
                  </p>
                  <form className="centerbox">
                    <div className="row mart30pr">
                      {/* <div className="col-md-6 ">
                        <div className="texinput">
                          <input
                            type="text"
                            name="name"
                            placeholder="Full name"
                            value={name}
                            onChange={(e) => {
                              setname(
                                e.target.value.length < 26
                                  ? e.target.value
                                  : name
                              );
                            }}
                            onKeyPress={(event) => {
                              if (!/[a-zA-Z ]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <span className="starbox">
                            <i className="fas fa-star"></i>
                          </span>
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        {/* <input type="text" name="username" placeholder='UserName' max="5" value={username.toLowerCase()} onChange={(e) => { setusername(e.target.value.length <26 ? e.target.value : username) }} /><br /> */}
                        <input
                          type="text"
                          name="username"
                          placeholder=" Enter guest username"
                          value={username.toLowerCase()}
                          onChange={(e) => {
                            setusername(
                              e.target.value.length < 26
                                ? e.target.value
                                : username
                            );
                          }}
                          onKeyPress={(event) => {
                            if (/[, ,]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <span className="starbox">
                          <i className="fas fa-star"></i>
                        </span>
                      </div>
                    </div>

                    <div className="row ">
                      {/* <div className="col-md-6">
                        <div className="texinput">
                          <DatePicker
                            id="myDate"
                            max={enddate}
                            format="yyyy-MM-dd"
                            onChange={(date) => setSelectedDate(date)}
                            placeholder="Date of birth"
                          /> */}

                      {/* <span className='starbox' ><i className="fas fa-star"></i></span> */}
                      {/* </div> 
                      </div> */}
                      <div className="col-md-12">
                        <input
                          type="text"
                          name="code"
                          placeholder="Referral code"
                          value={referralcode}
                          onChange={(e) => {
                            setreferralcode(
                              e.target.value.length < 7
                                ? e.target.value
                                : referralcode
                            );
                          }}
                          onKeyPress={(event) => {
                            if (!/[a-zA-Z0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className="col-md-12">
                        <div className="texinput">
                          <input
                            type="text"
                            name="username"
                            placeholder="Enter email"
                            value={email.toLowerCase()}
                            onChange={(e) => {
                              setemail(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              if (!/[a-zA-Z0-9@.]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <span className="starbox">
                            <i className="fas fa-star"></i>
                          </span>
                          <Button
                          className="btnbox text-center mb-3"
                          style={{ backgroundColor: color }}
                          id="send-otp"
                          onClick={sendOtp}
                        >
                          GET OTP
                        </Button>
                        </div>
                      </div> */}
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="texinput">
                          <input
                            className="pass-p-absolute"
                            type={passwordShown ? "text" : "password"}
                            name="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => {
                              setPassword(
                                e.target.value.length < 16
                                  ? e.target.value
                                  : password
                              );
                            }}
                          />
                          <i
                            className="far fa-eye field-icon"
                            style={{
                              color: "white",
                              position: "absolute",
                              right: "0rem",
                              cursor: "pointer",
                            }}
                            id="togglePassword"
                            onClick={togglePasswordVisibility}
                          ></i>
                          <span className="starbox martpass7p"></span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="texinput">
                          <input
                            className="pass-p-absolute"
                            type={confirmpasswordShown ? "text" : "password"}
                            name="password"
                            placeholder="Enter confirm password"
                            value={confirmpassword}
                            onChange={(e) => {
                              setConfirmPassword(
                                e.target.value.length < 16
                                  ? e.target.value
                                  : confirmpassword
                              );
                            }}
                          />
                          <i
                            className="far fa-eye field-icon"
                            style={{
                              color: "white",
                              right: "0rem",
                              top: "26px",
                              cursor: "pointer",
                            }}
                            id="togglePassword"
                            onClick={toggleconfirmPasswordVisibility2}
                          ></i>

                          <span className="starbox"></span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row">
  <div className="col-md-12">
  <textarea type="text " name="bio" placeholder='Bio' value={bio} onChange={(e)=>{setbio(e.target.value)}}></textarea>

  </div>
 
</div> */}

                    <div className="row">
                      <div className="centered regis-btn-mart40p">
                        <Button
                          className="btnbox text-center mb-3"
                          style={{ backgroundColor: color }}
                          id="submit-register"
                          onClick={register}
                        >
                          Sign Up
                        </Button>
                      </div>
                    </div>
                  </form>
                  <p className="text-center">
                    <Link to="/guest">
                      <b style={{ color: "black" }}>Click here to login</b>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {popshow ? (
            <>
              <div className="modalmaindiv" id="welcomeDiv">
                <div className="modaldiv">
                  <div
                    className=" cardbox "
                    style={{ backgroundColor: color1 }}
                  >
                    {color1 === "#C70100" ? (
                      <>
                        <div className="eorriconbox">
                          <i className="fa fa-exclamation-triangle"></i>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="eorriconbox">
                          <i className="fa fa-badge-check"></i>
                        </div>
                      </>
                    )}

                    <p className="text-center text-white">{error}</p>

                    <div className="mainerrorclosebtn">
                      {color1 === "#C70100" ? (
                        <>
                          <div
                            className="errorclosebtn "
                            id="close-pop"
                            onClick={() => {
                              setpopshow(false);
                            }}
                          >
                            <p>OK</p>
                          </div>
                        </>
                      ) : (
                        <Link to="/guest" style={{ textDecoration: "none" }}>
                          <div
                            className="errorclosebtn1 "
                            onClick={() => {
                              setpopshow(false);
                            }}
                          >
                            <p>OK</p>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}
