import React, { useEffect, useState } from "react";
import Menubar from "./Menubar";
import Sound from "./Sound";
export default function Dictionary(props) {
  const [settingshow, setsettingshow] = useState(props.setting);
  useEffect(() => {
    let bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.className = "Dashbord";
  });
  return (
    <>
      <Menubar setpop={setsettingshow} audioRef={props.audio} />

      <div className="mainbox">
        <div className="container">
          <div class="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <p className="font40p text-center">MONTHLY COMPETITIONS</p>
              <div className=" mart26pa aim-of-the-game-graphic">
                <p className="aim-content">
                  {" "}
                  <strong class="the-basic-norm text-deunder">
                    WORLDWIDE, NATIONAL & LOCAL MONTHLY COMPETITIONS{" "}
                  </strong>
                </p>
                <p className="aim-content">
                  NE-Lite provides you with these above listed monthly
                  competitions.
                </p>
                <p className="dic-mar aim-content">
                  You play to score as many points as you can during the month.
                </p>

                <br />

                <p className="aim-content">
                  Your points are totalled for you and the dashboard shows your
                  monthly points total and also your rankings worldwide,
                  nationally and locally in your own age group, all in real time
                  as you play.
                </p>
                <br />

                <p className="aim-content">
                  On the first of each month, point totals are reset to zero to
                  provide an equal <br />
                  starting point for all players, old and new.
                </p>
                <br />
                <p className="aim-content">
                  <i>
                    See "points" in the avatar menu for information on how
                    points are scored.
                  </i>
                </p>

                <p
                  style={{ marginTop: "10px", textAlign: "left" }}
                  class="the-basic-norm"
                >
                  OWN PRIVATE PERSONAL MONTHLY COMPETITION AND POINTS TARGET
                </p>
                <p
                  style={{ marginTop: "0px", textAlign: "left" }}
                  class=" aim-content"
                >
                  Your "history" records your monthly totals for you so that you
                  can see your best month's total and have a target to improve
                  upon. Your best ever monthly total is displayed on your
                  dashboard as your target to aim at, along with your current
                  monthly points total so you can see, in real time, how you are
                  doing at any time during the month
                  <br />
                 <b>  All monthly points will be reset to zero at 00.00 GMT (London
                  Europe time)</b>
                </p>
                <div className="hr-div" style={{width:"20%", margin:"auto"}}>
                <hr />
                </div>
                <p className="details-div">For Details of Monthly Competitions <br />
                visit https://ne-lite.com

                </p>


              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>

        <br />

        {settingshow ? (
          <Sound settinghide={setsettingshow} audioRef={props.audio} />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
