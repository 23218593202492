import { useEffect, useState } from "react";

import Menubar from "./Menubar";
import { useHistory } from "react-router-dom";
// const amount = "2.00"

export default function Paymentplan(props) {
  const history = useHistory();
  const [settingshow, setsettingshow] = useState(props.setting);
  const [paypalstate, setpaypalState] = useState(true);
  const [currencyamount, setcurrencyamount] = useState("");
  const [count, setcount] = useState("");
  const [showpop, setshowpop] = useState(false);
  const [messagevalue, setmessagevalue] = useState("");
  const [transactionpop, settransactionpop] = useState(false);
  const [color, setcolor] = useState("");
  // const history = useHistory();
  const [transactiondetail, settransactiondetail] = useState([
    { TransactionId: "0", TotalHint: "0", PaidAmount: "0" },
  ]);

  useEffect(() => {
    let bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.className = "Dashbord";
  }, []);

  // console.log(count,"pojja");
  function payamount(e) {
    setcount(e.target.value);
    var value = e.target.name;
    // window.location.reload();
    window.sessionStorage.setItem("pp", value);
    window.sessionStorage.setItem("checkreload", "1");
    // window.location.reload();
    // window.open("/Payforgame","_self");
    history.push("/Payforgame");
  }
  function getweek() {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var date =
      new Date(window.sessionStorage.getItem("createdAt")).getFullYear() +
      "-" +
      String(
        monthNames[
          new Date(window.sessionStorage.getItem("createdAt")).getMonth()
        ]
      ) +
      "-" +
      new Date(window.sessionStorage.getItem("createdAt")).getDate();
    // var firstDay = new Date(date);
    // var nextWeek = new Date(firstDay.getTime() +1* 24 * 60 * 60 * 1000);

    // nextWeek.setMinutes(nextWeek.getMinutes() + nextWeek.getTimezoneOffset())

    // let dateStr = nextWeek.getDate() + "-" + ((nextWeek.getMonth() + 1) < 10 ? "0" + (nextWeek.getMonth() + 1) : (nextWeek.getMonth() + 1)) + "-" + nextWeek.getFullYear();

    return date;
  }
  return (
    <div>
      <Menubar setpop={setsettingshow} audioRef={props.audio} />

      <div className="container-fluid">
        <div className="containermain">
          <div className="paypalbox1">
            <div
              className="container"
              style={{
                color: "black",
                backgroundImage: "linear-gradient(#0170cd, #0170cd)",
                padding: "20px",
                borderRadius: "5px",
              }}
            >
              {/* <b><h5 className="text-center" style={{ fontWeight: "600" }}>NE LITE FEES</h5></b> */}
              <div className="row ">
                <p className="color-white-payment-plan ">
                  The game is free to play at easy level :
                </p>
                <br />
                <br />
                <p className="color-white-payment-plan">
                  To upgrade to the more difficult levels, please select one of
                  the following options:
                </p>
              </div>
              <div className="row">
                <div className="col-md-12" style={{ marginTop: "5px" }}>
                  <p className="color-white-payment-plan ">
                    <b>{/* purchase the game for either */}</b>
                  </p>
                  {/* <p>If purchased within 7 days of download - &#xa3;0.49 a month payable as &#xa3;5.88 for the year. You download the app on <span style={{ color: "blue" }}>{date.current}</span> so purchase by <span style={{ color: "blue" }}>{dateweek.current}</span> to qualify for the early bird fee. You will own and be able to play NE Lite until <span style={{ color: "blue" }}>{dateyear.current} </span></p> */}
                </div>
              </div>
              <div>
                <br />
                <div className="row" style={{ textAlign: "center" }}>
                  <div
                    className="col-md-12"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <table className="payment-plan-table">
                      <thead style={{ borderBottom: "1px solid #fff" }}>
                        <td
                          className="color-white-payment-plan "
                          style={{ width: "100px", fontWeight: "700" }}
                        >
                          Sr. No
                        </td>
                        <td
                          className="color-white-payment-plan "
                          style={{ width: "100px", fontWeight: "700" }}
                        >
                          Price
                        </td>
                        <td
                          className="color-white-payment-plan"
                          style={{ width: "100px", fontWeight: "700" }}
                        >
                          Duration
                        </td>
                        <td
                          className="color-white-payment-plan"
                          style={{ width: "100px", fontWeight: "700" }}
                        >
                          Action
                        </td>
                      </thead>
                      <br />
                      <tbody>
                        <tr>
                          <td
                            className="color-white-payment-plan "
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            (1)
                          </td>
                          <td
                            className="color-white-payment-plan"
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            USD $1.99
                          </td>
                          <td
                            className="color-white-payment-plan "
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            a month includes medium level
                          </td>
                          <td style={{ width: "100px", fontWeight: "700" }}>
                            {window.sessionStorage.getItem("subscribe") !==
                            "" ? (
                              <button
                                type="submit"
                                className="btn mediumlevel color-white-payment-plan bg-btn-dark-blue"
                                name="1.99"
                                value="5"
                                onClick={payamount}
                              >
                                BUY NOW
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn mediumlevel"
                                name="1.99"
                                value="5"
                                onClick={payamount}
                              >
                                BUY NOW
                              </button>
                            )}
                          </td>
                        </tr>
                      </tbody>

                      <tbody>
                        <tr>
                          <td
                            className="color-white-payment-plan "
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            (2)
                          </td>
                          <td
                            className="color-white-payment-plan"
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            USD $2.99
                          </td>
                          <td
                            className="color-white-payment-plan "
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            a month includes expert level
                          </td>
                          <td style={{ width: "100px", fontWeight: "700" }}>
                            {window.sessionStorage.getItem("subscribe") !==
                            "" ? (
                              <button
                                type="submit"
                                className="btn mediumlevel color-white-payment-plan bg-btn-dark-blue"
                                name="2.99"
                                value="6"
                                onClick={payamount}
                              >
                                BUY NOW
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn mediumlevel"
                                name="2.99"
                                value="6"
                                onClick={payamount}
                              >
                                BUY NOW
                              </button>
                            )}
                          </td>
                        </tr>
                      </tbody>

                      <tbody>
                        <tr>
                          <td
                            className="color-white-payment-plan "
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            (3)
                          </td>
                          <td
                            className="color-white-payment-plan"
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            USD $3.99
                          </td>
                          <td
                            className="color-white-payment-plan "
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            a month includes genius level
                          </td>
                          <td style={{ width: "100px", fontWeight: "700" }}>
                            {window.sessionStorage.getItem("subscribe") !==
                            "" ? (
                              <button
                                type="submit"
                                className="btn mediumlevel color-white-payment-plan bg-btn-dark-blue"
                                name="3.99"
                                value="7"
                                onClick={payamount}
                              >
                                BUY NOW
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn mediumlevel"
                                name="3.99"
                                value="7"
                                onClick={payamount}
                              >
                                BUY NOW
                              </button>
                            )}
                          </td>
                        </tr>
                      </tbody>

                      <tbody>
                        <tr>
                          <td
                            className="color-white-payment-plan "
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            (4)
                          </td>
                          <td
                            className="color-white-payment-plan"
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            USD $14.99
                          </td>
                          <td
                            className="color-white-payment-plan "
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            6 Months includes all levels
                          </td>
                          <td style={{ width: "100px", fontWeight: "700" }}>
                            {window.sessionStorage.getItem("subscribe") !==
                            "" ? (
                              <button
                                type="submit"
                                className="btn mediumlevel color-white-payment-plan bg-btn-dark-blue"
                                name="14.99"
                                value="1"
                                onClick={payamount}
                              >
                                BUY NOW
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn mediumlevel"
                                name="14.99"
                                value="1"
                                onClick={payamount}
                              >
                                BUY NOW
                              </button>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {/* <div className="container-fluid">
                                    <div className="row">
                                      <div className="col-md-12">
                                      <h5><b>OR</b></h5>
                                      </div>
                                  </div>
                                    </div> */}

                    <table>
                      <tbody>
                        <tr>
                          <td
                            className="color-white-payment-plan "
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            (5)
                          </td>
                          <td
                            className="color-white-payment-plan "
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            USD $17.99
                          </td>
                          <td
                            className="color-white-payment-plan "
                            style={{ width: "100px", fontWeight: "700" }}
                          >
                            12 Months includes all levels
                          </td>
                          <td style={{ width: "100px", fontWeight: "700" }}>
                            {window.sessionStorage.getItem("subscribe") !==
                            "" ? (
                              <button
                                type="text"
                                className="btn mediumlevel color-white-payment-plan bg-btn-dark-blue "
                                name="17.99"
                                value="2"
                                onClick={payamount}
                              >
                                BUY NOW
                              </button>
                            ) : (
                              <button
                                type="text"
                                className="btn mediumlevel color-white-payment-plan"
                                name="17.99"
                                value="2"
                                onClick={payamount}
                              >
                                BUY NOW
                              </button>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* <div className="row" style={{marginTop:"5px"}}>
                            <div className="col-md-11">
                                <b>FOR SCHOOLS ONLY</b>
                            </div>
                        
                            <div className="col-md-2">
                                <button type="text" className="btn mediumlevel" name="0.00" value="50" >Buy Now</button>
                            </div>
                        </div> */}
            </div>
          </div>
        </div>

        <div className="container">
          <div className="buy-advertisment-img">
            <span className="usdoller">USD $24.99</span>
            <div className="buy-the-board-game-link">
              <a target="_blank" href="https://ne-games.com/products/2">
                BUY THE BOARD GAME
              </a>
            </div>
            <img className="img-responsive" src="images/img1.png"></img>
          </div>
        </div>
      </div>
    </div>
  );
}
