import React, { useState, useEffect } from "react";
import Menubar from "./Menubar";
import Button from "react-bootstrap/Button";

export default function SuggestWordMeaning(props) {
  const [settingshow, setsettingshow] = useState(props.setting);
  const [word, setword] = useState("");
  const [defination, setdefination] = useState("");
  const [color1, setcolor1] = useState("");
  const [error, seterror] = useState("");
  const [popshow, setpopshow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    let bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.className = "Dashbord";
  });

  async function addword() {
    if (word === "") {
      seterror("Word is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (defination === "") {
      seterror("Meaning is Required");
      setpopshow(true);
      setcolor1("#C70100");
    } else {
      var myHeaders = new Headers();
      myHeaders.append(
        "APPKEY",
        "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
      );
      setLoading(true);
      setDisable(true);
      var formdata = new FormData();
      formdata.append("word", word);
      formdata.append("definition", defination);
      formdata.append("user_id", window.sessionStorage.getItem("id"));

      const url = `${process.env.REACT_APP_URL}/api/addSuggestedWord`;

      await fetch(url, {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response.status === 200) {
            seterror(response.success_message);
            setpopshow(true);
            setcolor1("#00c767");
            setword("");
            setdefination("");
            setLoading(false);
            setDisable(false);
          } else {
            seterror(response.error_message);
            setpopshow(true);
            setcolor1("#C70100");
            setLoading(false);
          }
        });
    }
  }

  return (
    <>
      <Menubar setpop={setsettingshow} audioRef={props.audio} />
      <br />
      <br />
      <br />

      <div className="container-fluid">
        <div className="formbox mart60pword">
          <div className="formboxs p-4 pt-4">
            <h4 className="text-center" style={{ color: "white" }}>
              Suggest a new word
            </h4>

            <input
              type="text"
              name="name"
              placeholder="Enter word*"
              autoComplete="off"
              value={word}
              maxLength={26}
              onChange={(e) => {
                setword(e.target.value);
              }}
              //  onChange={(e) => { setword(e.target.value.length <26 ? e.target.value : name) }}
              onKeyPress={(event) => {
                if (!/[a-zA-Z]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <br />
            <p className="color-white-new">
              <strong> Type in the box below:</strong>
              <br />
              If suggesting: <br />
              (1) an addition, begin with "add" then "meaning"
              <br />
              (2) a change of meaning, begin with "change to"
              <br />
              (3) a deletion, just type 'delete'
            </p>
            <br />

            <textarea
              type="text"
              name="username"
              placeholder="Type here*"
              autoComplete="off"
              value={defination}
              onChange={(e) => {
                setdefination(e.target.value);
              }}
            ></textarea>
            <br />
            <div
              className="container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Button
                className="  btnbox text-center mb-1 suggest-word-meaning"
                onClick={addword}
                disabled={disable}
              >
                <span style={{ color: "white" }}>Submit</span>
              </Button>
              {/* <Button className='  btnbox text-center mb-1 suggest-word-meaning' onClick={addword} style={{borderRadius:"18px"}} > CHANGE A MEANING</Button>*/}
              {/*<Button className='  btnbox  text-center mb-1 suggest-word-meaning' onClick={addword}  >REMOVE A WORD</Button>*/}
            </div>
          </div>
        </div>
      </div>

      {popshow ? (
        <>
          <div className="modalmaindiv" id="welcomeDiv">
            <div className="modaldiv">
              <div className=" cardbox " style={{ backgroundColor: color1 }}>
                {color1 === "#C70100" ? (
                  <>
                    <div className="eorriconbox">
                      <i className="fa fa-exclamation-triangle"></i>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="eorriconbox">
                      <i className="fa fa-badge-check"></i>
                    </div>
                  </>
                )}

                <br />
                <h2 className="text-center text-white">{error}</h2>
                <br />
                <div className="mainerrorclosebtn">
                  <div
                    className="errorclosebtn "
                    onClick={() => {
                      setpopshow(false);
                    }}
                  >
                    <p>OK</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {loading ? (
        <>
          <div className="modalmaindiv" id="welcomeDiv">
            <div className="modaldiv model-loading-space">
              <div
                className=" cardbox "
                style={{ backgroundColor: "transparent" }}
              >
                <h2 className="text-center text-white"></h2>

                <div className="mainerrorclosebtn loading-img-width">
                  <div className="loader-new "></div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
