import { useEffect, useState } from "react";

import Menubar from "./Menubar";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
// const amount = "2.00"
const currency = "USD";
const style = { layout: "vertical" };

// Custom component to wrap the PayPalButtons and handle currency changes

export default function Paypal(props) {
  const [settingshow, setsettingshow] = useState(props.setting);
  const [paypalstate, setpaypalState] = useState(true);
  const [currencyamount, setcurrencyamount] = useState("");
  const [count, setcount] = useState("");
  const [showpop, setshowpop] = useState(false);
  const [messagevalue, setmessagevalue] = useState("");
  const [transactionpop, settransactionpop] = useState(false);
  const [color, setcolor] = useState("");
  const history = useHistory();
  const [transactiondetail, settransactiondetail] = useState([
    { TransactionId: "0", TotalHint: "0", PaidAmount: "0" },
  ]);
  useEffect(() => {
    let bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.className = "Dashbord";
  }, []);

  const payamount = (e) => {
    // e.preventDefault()

    window.sessionStorage.setItem("hint_count", e.target.value);

    var value = e.target.name;

    window.sessionStorage.setItem("pph", value);

    window.sessionStorage.setItem("checkreloadhint", "1");

    history.push("/Payforhint");
    // history.push("/Stripehint")
    //window.open("/Payforhint","_self");
  };
  return (
    <div>
      <Menubar setpop={setsettingshow} audioRef={props.audio} />

      {showpop ? (
        <div className="modalmaindiv" id="welcomeDiv1">
          <div className="modaldiv">
            <div className=" cardbox " style={{ backgroundColor: color }}>
              <p className="text-center text-white">
                <h4>{messagevalue}</h4>
              </p>
              <br />
              {transactionpop ? (
                <>
                  <div
                    className="row"
                    style={{ transform: "translate(71px, 10px)" }}
                  >
                    <div className="col-md-4 text-center text-white">
                      <h5>Transaction Id:</h5>
                    </div>
                    <div className="col-md-4 text-center text-white">
                      <h5>{transactiondetail[0].TransactionId}</h5>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row"
                    style={{ transform: "translate(71px, 10px)" }}
                  >
                    <div className="col-md-4 text-center text-white">
                      <h5>Total Hint:</h5>
                    </div>
                    <div className="col-md-4 text-center text-white">
                      <h5>{transactiondetail[0].TotalHint}</h5>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row"
                    style={{ transform: "translate(71px, 10px)" }}
                  >
                    <div className="col-md-4 text-center text-white">
                      <h5>Paid Amount:</h5>
                    </div>
                    <div className="col-md-4 text-center text-white">
                      <h5>${transactiondetail[0].PaidAmount}</h5>
                    </div>
                  </div>
                  <br />
                </>
              ) : (
                ""
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Link to="/Dashboard">
                    <button
                      type="button"
                      className="mainroundbtn1 roundbtn"
                      onClick={() => {
                        setshowpop(false);
                      }}
                      style={{ width: "200px" }}
                    >
                      {" "}
                      OK
                    </button>
                  </Link>
                </div>
                {/* <Link to="Dashboard"><div className="mainroundbtn1" onClick={()=>{ setshowpop(false)}}>
<img src="./nextplaybutton.png" className="roundbtn" style={{transform:"translate(38px, 2px)",width:"68%"}} alt="..."/>
</div></Link>
      */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {paypalstate ? (
        <>
          <div className="container-fluid">
            <div className="containermain">
              <div className="paypalbox">
                <div
                  className="container"
                  style={{
                    color: "black",
                    backgroundImage: "linear-gradient(#0170cd, #0170cd)",
                    padding: "20px",
                    borderRadius: "5px",
                  }}
                >
                  <b>
                    <h4
                      className="text-center color-white-payment-plan"
                      style={{ fontWeight: "700" }}
                    >
                      Hint Packages
                    </h4>
                  </b>
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="color-white-payment-plan ">
                        5 Hints for USD $0.89
                      </h5>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-2">
                      <button
                        type="text"
                        className="btn mediumlevel bg-btn-dark-blue-hints"
                        name="0.89"
                        value="5"
                        onClick={payamount}
                      >
                        BUY NOW
                      </button>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="color-white-payment-plan ">
                        15 Hints for USD $1.79
                      </h5>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-2">
                      <button
                        type="text"
                        className="btn mediumlevel bg-btn-dark-blue-hints"
                        name="1.79"
                        value="15"
                        onClick={payamount}
                      >
                        BUY NOW{" "}
                      </button>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="color-white-payment-plan ">
                        30 Hints for USD $2.49
                      </h5>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-2">
                      <button
                        type="text"
                        className="btn mediumlevel bg-btn-dark-blue-hints"
                        name="2.49"
                        value="30"
                        onClick={payamount}
                      >
                        BUY NOW
                      </button>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="color-white-payment-plan ">
                        60 Hints for USD $3.49
                      </h5>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn mediumlevel bg-btn-dark-blue-hints"
                        name="3.49"
                        value="60"
                        onClick={payamount}
                      >
                        BUY NOW{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="App" style={{ color: "white" }}>
            <p style={{ float: "right", color: "black" }}>
              USD: ${currencyamount}
            </p>
            <br />
          </div>
        </>
      )}
    </div>
  );
}
