import { useEffect, useState } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import Menubar from "./Menubar";
import { useHistory } from "react-router-dom";
import StripeContainer from "./StripeContainer";
// const amount = "2.00"
const currency = "USD";
const style = { layout: "vertical" };

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({
  transpop,
  transvalue,
  countvalue,
  col,
  msg,
  pop,
  changestate,
  amount,
  currency,
  showSpinner,
}) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency, showSpinner]);

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[amount, currency, style]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            })
            .then((orderId) => {
              // Your code here after create the order
              return orderId;
            });
        }}
        onApprove={async function (data, actions) {
          return actions.order.capture().then(async function (response) {
            if (response.status === "COMPLETED") {
              changestate(true);

              msg("Your Transaction has been Successfull");
              pop(true);
              col("#00c767");
              transvalue([
                {
                  TransactionId:
                    response.purchase_units[0].payments.captures[0].id,
                  TotalHint: countvalue,
                  PaidAmount: amount,
                },
              ]);
              transpop(true);
              const url3 = `${process.env.REACT_APP_URL}/api/buyPackage`;

              var myHeaders = new Headers();
              myHeaders.append(
                "APPKEY",
                "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
              );

              var formdata = new FormData();
              formdata.append("user_id", window.sessionStorage.getItem("id"));
              formdata.append("package_id", countvalue);

              formdata.append(
                "payment_id",
                response.purchase_units[0].payments.captures[0].id
              );
              formdata.append("amount", amount);
              formdata.append("bankname", "");
              formdata.append("coupon_code", "");
              formdata.append("payment_status", "1");
              formdata.append(
                "transaction_id",
                response.purchase_units[0].payments.captures[0].id
              );

              await fetch(url3, {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
              })
                .then((resp) => resp.json())
                .then(async (resp) => {
                  if (resp.status === 200 && resp.message === "Success") {
                    
                  }
                });
            } else {
              msg("Your Transaction has been Failed");
              changestate(true);
              pop(true);
              col("#C70100");
              transpop(false);
              const url3 = `${process.env.REACT_APP_URL}/api/buyPackage`;

              myHeaders = new Headers();
              myHeaders.append(
                "APPKEY",
                "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
              );

              formdata = new FormData();
              formdata.append("user_id", window.sessionStorage.getItem("id"));
              formdata.append("package_id", countvalue);
              formdata.append("payment_id", "0000");
              formdata.append("amount", amount);
              formdata.append("bankname", "");
              formdata.append("coupon_code", "");
              formdata.append("payment_status", "4");
              formdata.append("transaction_id", "");

              await fetch(url3, {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
              })
                .then((resp) => resp.json())
                .then(async (resp) => {
                  if (resp.status === 200 && resp.message === "Success") {
                  }
                });
            }
          });
        }}
        onError={async function (err) {
          alert("hello");
        }}
      />
    </>
  );
};
export default function Paymentplan(props) {
  const history = useHistory();
  const [settingshow, setsettingshow] = useState(props.setting);
  const [paypalstate, setpaypalState] = useState(true);
  const [currencyamount, setcurrencyamount] = useState("");
  const [count, setcount] = useState("");
  const [showpop, setshowpop] = useState(false);
  const [messagevalue, setmessagevalue] = useState("");
  const [transactionpop, settransactionpop] = useState(false);
  const [color, setcolor] = useState("");
  const [transactiondetail, settransactiondetail] = useState([
    { TransactionId: "0", TotalHint: "0", PaidAmount: "0" },
  ]);

  var checkreload = window.sessionStorage.getItem("checkreload");

  useEffect(() => {
    let bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.className = "Dashbord";
  }, []);

  useEffect(() => {
    var value = window.sessionStorage.getItem("pp");

    var pvc = window.localStorage.getItem("pvc");

    setcurrencyamount(value);
    //   if(checkreload === '1'){

    //    if(pvc){
    //     window.sessionStorage.setItem("checkreload","0");
    //    }
    //     else{
    //         window.sessionStorage.setItem("checkreload","1");
    //         window.location.reload();
    //         window.location.reload();

    //     }

    //   }
  }, []);

  function getweek() {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var date =
      new Date(window.sessionStorage.getItem("createdAt")).getFullYear() +
      "-" +
      String(
        monthNames[
          new Date(window.sessionStorage.getItem("createdAt")).getMonth()
        ]
      ) +
      "-" +
      new Date(window.sessionStorage.getItem("createdAt")).getDate();
    // var firstDay = new Date(date);
    // var nextWeek = new Date(firstDay.getTime() +1* 24 * 60 * 60 * 1000);

    // nextWeek.setMinutes(nextWeek.getMinutes() + nextWeek.getTimezoneOffset())

    // let dateStr = nextWeek.getDate() + "-" + ((nextWeek.getMonth() + 1) < 10 ? "0" + (nextWeek.getMonth() + 1) : (nextWeek.getMonth() + 1)) + "-" + nextWeek.getFullYear();

    return date;
  }

  return (
    <div>
      <Menubar setpop={setsettingshow} audioRef={props.audio} />

      {showpop ? (
        <div className="modalmaindiv" id="welcomeDiv1">
          <div className="modaldiv">
            <div className=" cardbox " style={{ backgroundColor: color }}>
              <p className="text-center text-white">
                <h3>{messagevalue}</h3>
              </p>
              <br />
              {transactionpop ? (
                <>
                  <div
                    className="row"
                    style={{ transform: "translate(71px, 10px)" }}
                  >
                    <div className="col-md-4 text-center text-white">
                      <h5>Transaction Id:</h5>
                    </div>
                    <div className="col-md-4 text-center text-white">
                      <h5>{transactiondetail[0].TransactionId}</h5>
                    </div>
                  </div>

                  <br />
                  <div
                    className="row"
                    style={{ transform: "translate(71px, 10px)" }}
                  >
                    <div className="col-md-4 text-center text-white">
                      <h5>Paid Amount:</h5>
                    </div>
                    <div className="col-md-4 text-center text-white">
                      <h5>${transactiondetail[0].PaidAmount}</h5>
                    </div>
                  </div>
                  <br />
                </>
              ) : (
                ""
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="button"
                    className="mainroundbtn1 roundbtn"
                    onClick={() => {
                      setshowpop(false);
                      window.sessionStorage.clear();
                      window.location.reload();
                      window.open("/", "_self");
                    }}
                    style={{ width: "200px" }}
                  >
                    OK
                  </button>
                </div>
                {/* <Link to="Dashbord"><div className="mainroundbtn1" onClick={()=>{ setshowpop(false)}}>
<img src="./nextplaybutton.png" className="roundbtn" style={{transform:"translate(38px, 2px)",width:"68%"}} alt="..."/>
</div></Link>
*/}
              </div>
              {/* <div style={{display: "flex",
justifyContent: "center"}}>
<div className="mainroundbtn1" onClick={()=>{ setshowpop(false);window.sessionStorage.clear();
window.location.reload();
window.open("/", "_self")}}>
<img src="./nextplaybutton.png" className="roundbtn" style={{transform:"translate(38px, 2px)",width:"68%"}} alt="..."/>
</div>

</div> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <>
        <br />
        <br />

        <div className="App" style={{ color: "white" }}>
          <p style={{ float: "right", color: "black" }}>
            USD: ${currencyamount}
          </p>
          <br />
          <StripeContainer />
        </div>
      </>
    </div>
  );
}
