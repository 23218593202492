import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Menubar from "./Menubar";
import Sound from "./Sound";

export default function Feedback(props) {
  const [settingshow, setsettingshow] = useState(props.setting);

  const imagefile = useRef();

  const [name, setname] = useState("");

  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [message, setmessage] = useState("");

  const [color1, setcolor1] = useState("");
  const [error, seterror] = useState("");
  const [popshow, setpopshow] = useState(false);
  const [catcherror, setcatcherror] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    let bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.className = "Dashbord";
  });
  const validateEmail = (email) => {
    var regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  async function editprofile() {
    if (name === "") {
      seterror("Name is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (email === "") {
      seterror("Email is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (email !== "" && !validateEmail(email)) {
      seterror("Email is not valid");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (mobile.length > 1 && mobile.length < 10) {
      seterror("Please enter 10 digit mobile number");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (message === "") {
      seterror("Message is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else {
      var myHeaders = new Headers();
      myHeaders.append(
        "APPKEY",
        "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
      );
      setLoading(true);
      setDisable(true);
      var formdata = new FormData();
      formdata.append("name", name);
      formdata.append("email", email);
      if (mobile === "") {
        formdata.append("mobile", "88888888");
      } else {
        formdata.append("mobile", mobile);
      }

      formdata.append("message", message);
      formdata.append("user_id", window.sessionStorage.getItem("id"));

      const url = `${process.env.REACT_APP_URL}/api/feedback`;

      await fetch(url, {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response.status === 200 && response.message === "Success") {
            seterror(response.success_message);
            setpopshow(true);
            setLoading(false);
            setDisable(false);
            setcolor1("#00c767");
          } else {
            seterror(response.error_message);
            setpopshow(true);
            setLoading(false);
            setDisable(false);
            setcolor1("#C70100");
          }
        })
        .catch((err) => {
          setcatcherror(true);
          seterror("Please check all Field");

          setcolor1("#C70100");
        });
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        document.getElementById("submit-editprofile").click();
      }
    });
  }, []);

  return (
    <>
      <Menubar setpop={setsettingshow} audioRef={props.audio} />

      {catcherror ? (
        <>
          <div className="modalmaindiv" id="welcomeDiv">
            <div className="modaldiv">
              <div className=" cardbox " style={{ backgroundColor: color1 }}>
                <div className="eorriconbox">
                  <i className="fa fa-exclamation-triangle"></i>
                </div>

                <br />
                <h2 className="text-center text-white">{error}</h2>
                <br />
                <div className="mainerrorclosebtn">
                  <div
                    className="errorclosebtn "
                    onClick={() => {
                      setpopshow(false);
                      window.location.reload();
                    }}
                  >
                    <p>OK</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid">
            <div className="formbox1">
              <div className="formboxs">
                <h2 className="text-center" style={{ color: "#fff" }}>
                  Feedback
                </h2>
                <form className="centerbox">
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        name="name"
                        className="feddback-mar"
                        placeholder="Name*"
                        value={name}
                        onChange={(e) => {
                          setname(
                            e.target.value.length < 26 ? e.target.value : name
                          );
                        }}
                        onKeyPress={(event) => {
                          if (!/[A-Z a-z]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="email"
                        name="town"
                        className="feddback-mar"
                        placeholder="Email*"
                        value={email.toLowerCase()}
                        onChange={(e) => {
                          setemail(e.target.value);
                        }}
                        onKeyPress={(event) => {
                          if (!/[A-Za-z0-9.@]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        name="state"
                        className="feddback-mar"
                        placeholder="Mobile(optional)"
                        value={mobile}
                        onChange={(e) => {
                          setmobile(
                            e.target.value.length < 11 ? e.target.value : mobile
                          );
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <br />
                    </div>
                  </div>

                  <textarea
                    type="text"
                    name="bio"
                    className="feddback-mar feddback-textarea"
                    placeholder="Message*"
                    value={message}
                    onChange={(e) => {
                      setmessage(
                        e.target.value.length < 501 ? e.target.value : message
                      );
                    }}
                  ></textarea>
                  <span className="feedbackno">
                    {String(message.length)}/500
                  </span>
                  <br />

                  <div className="centered">
                    <Button
                      className="  btnbox text-center mb-3"
                      id="submit-editprofile"
                      onClick={editprofile}
                      disabled={disable}
                    >
                      Submit
                    </Button>
                    <br />
                  </div>
                </form>
              </div>
            </div>
          </div>
          {popshow ? (
            <>
              <div className="modalmaindiv2" id="welcomeDiv">
                <div className="modaldiv">
                  <div
                    className=" cardbox "
                    style={{ backgroundColor: color1 }}
                  >
                    {color1 === "#C70100" ? (
                      <>
                        <div className="eorriconbox">
                          <i className="fa fa-exclamation-triangle"></i>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="eorriconbox">
                          <i className="fa fa-badge-check"></i>
                        </div>
                      </>
                    )}

                    <br />
                    <h2 className="text-center text-white">{error}</h2>
                    <br />
                    <div className="mainerrorclosebtn">
                      {color1 === "#C70100" ? (
                        <>
                          <div
                            className="errorclosebtn "
                            onClick={() => {
                              setpopshow(false);
                            }}
                          >
                            <p>OK</p>
                          </div>
                        </>
                      ) : (
                        <Link
                          to="/Dashboard"
                          style={{ textDecoration: "none" }}
                        >
                          <div
                            className="errorclosebtn1 "
                            onClick={() => {
                              setpopshow(false);
                            }}
                          >
                            <p>OK</p>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {loading ? (
            <>
              <div className="modalmaindiv" id="welcomeDiv">
                <div className="modaldiv model-loading-space">
                  <div
                    className=" cardbox "
                    style={{ backgroundColor: "transparent" }}
                  >
                    <h2 className="text-center text-white"></h2>

                    <div className="mainerrorclosebtn loading-img-width">
                      <div className="loader-new "></div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}
