import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import Menubar from "./Menubar";
import axios from "axios";
import { Link } from "react-router-dom";

// const currency = "USD";
// const style = { layout: "vertical" };
export default function PayementConfirmationScreen() {
  const [message, setMessage] = useState(null);
  const [transactionId, setTransactionId] = useState("");
  const [paymentIntent, setPaymentIntent] = useState("");
  const [redirectStatus, setRedirectStatus] = useState("");
  const [user, setUser] = useState();
  const [amount, setAmount] = useState();
  var userId = window.sessionStorage.getItem("id");
  var value = window.sessionStorage.getItem("pp");

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    const trandsactionId = new URLSearchParams(window.location.search).get(
      "transaction_id"
    );
    setTransactionId(trandsactionId);
    const paymentIntent = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );
    setPaymentIntent(paymentIntent);
    const paymentStatus = new URLSearchParams(window.location.search).get(
      "redirect_status"
    );
    setRedirectStatus(paymentStatus);

    if (!clientSecret) {
      return;
    }
    if (!paymentStatus) {
      return;
    }

    switch (paymentStatus) {
      case "succeeded":
        setMessage("Payment succeeded!");
        const url = `${process.env.REACT_APP_URL}/api/buyPackage`;

        var myHeaders = new Headers();
        myHeaders.append(
          "APPKEY",
          "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
        );

        var formdata = new FormData();
        formdata.append("user_id", window.sessionStorage.getItem("id"));
        formdata.append("package_id", "1");
        formdata.append("payment_id", "1");
        formdata.append("payment_status", "1");
        formdata.append("transaction_id", trandsactionId);
        // formdata.append("device_name", "web");
        formdata.append("device_type", "web");

        formdata.append("amount", value);
        fetch(url, {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        })
          .then((resp) => resp.json())
          .then((resp) => {
            if (resp.status === 200 && resp.message === "Success") {
              // setUser(resp.data.transaction_id);
              // setAmount(resp.data.amount);
            }
          });
        break;
      case "processing":
        setMessage("Your payment is processing.");
        var myHeaders = new Headers();
        myHeaders.append(
          "APPKEY",
          "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
        );

        var formdata = new FormData();
        formdata.append("user_id", window.sessionStorage.getItem("id"));
        formdata.append("package_id", "1");
        formdata.append("payment_id", "1");
        // formdata.append("bankname", "abc");
        // formdata.append("coupon_code", "cb");
        formdata.append("payment_status", "1");
        formdata.append("transaction_id", trandsactionId);
        formdata.append("amount", value);
        // formdata.append("device_name", "web");
        formdata.append("device_type", "web");
        // formdata.append("amount", window.sessionStorage.getItem("pp"));
        fetch(url, {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        })
          .then((resp) => resp.json())
          .then((resp) => {
            if (resp.status === 200 && resp.message === "Success") {
            }
          });
        break;
      case "requires_payment_method":
        setMessage("Your payment was not successful, please try again.");

        var myHeaders = new Headers();
        myHeaders.append(
          "APPKEY",
          "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
        );

        var formdata = new FormData();
        formdata.append("user_id", window.sessionStorage.getItem("id"));
        formdata.append("package_id", "1");
        formdata.append("payment_id", "1");
        // formdata.append("bankname", "abc");
        // formdata.append("coupon_code", "cb");
        formdata.append("payment_status", "0");
        formdata.append("transaction_id", trandsactionId);
        formdata.append("amount", value);
        formdata.append("device_type", "web");
        // formdata.append("amount", window.sessionStorage.getItem("pp"));
        fetch(url, {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        })
          .then((resp) => resp.json())
          .then((resp) => {
            if (resp.status === 200 && resp.message === "Success") {
            }
          });
        break;
      default:
        setMessage("Something went wrong.");
        var myHeaders = new Headers();
        myHeaders.append(
          "APPKEY",
          "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
        );

        var formdata = new FormData();
        formdata.append("user_id", window.sessionStorage.getItem("id"));
        formdata.append("package_id", "1");
        formdata.append("payment_id", "1");

        // formdata.append("bankname", "abc");
        // formdata.append("coupon_code", "cb");
        formdata.append("payment_status", "0");
        formdata.append("transaction_id", trandsactionId);
        // formdata.append("device_name", "web");
        formdata.append("device_type", "web");

        formdata.append("amount", value);
        // formdata.append("amount", window.sessionStorage.getItem("pp"));
        fetch(url, {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        })
          .then((resp) => resp.json())

          .then((resp) => {
            if (resp.status === 200 && resp.message === "Success") {
              setUser(resp.data.transaction_id);
            }
          });
        break;
    }
  }, []);

  return (
    <>
      <div className="modalmaindiv2">
        <div className="App new-payment-succes-login">
          <b></b>
          <h1>Your Payment Details:</h1>
          <br />
          <b>Message:</b>
          <div style={{ color: "green", fontWeight: "400" }}> {message} </div>
          <b>Transaction_Id:</b>
          <br />
          {transactionId}
          <br />
          <b>Your Amount:</b>
          <br />${value}
          <div className="ok-btn-center">
            <Link to="/">
              <button className="ok-btn-payment">OK</button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
