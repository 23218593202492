import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
export default function Dictionary(props) {
  const [settingshow, setsettingshow] = useState(props.setting)
  useEffect(() => {
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = "Dashbord";
  })
  return (
    <>
     

      <div className="mainbox">
      <Link className="navbar-brand" to="/"> <img src="./images/back-btn.png" class="extbtn mart20p" alt="..." height="30px" width="30px"></img></Link>
        
        <div className='container'>
      <div class="about-bot">
      <p>These applications collect some Personal Data from its Users.</p>
      <br/>
      <h1 className='privacy-policy'>POLICY SUMMARY</h1>

         <h2 className="terms-marg">Personal Data collected for the following purposes and using the following services:</h2>
         
         <h2 className="terms-marg">Advertising</h2>
         <ul className="float-none mb-3 ne-lite-new-privacy">
          <li><strong>AdColony, AppLovin, Chartboost, MoPub, Unity Ads, Vungle and Fyber</strong><br/>
           Personal Data: Cookies and Usage Data
           </li>
          <li><strong>AdMob, Appodeal and Facebook Audience Network</strong><br/>
          Personal Data: Cookies, unique device identifiers for advertising 
          (Google Advertiser ID or IDFA, for example) and Usage Data
           </li>
       
         </ul>
         
          <h2 className="terms-marg">Analytics</h2>
         <ul className="float-none mb-3 ne-lite-new-privacy">
          <li><strong>Facebook Analytics for Apps</strong><br/>
           Personal Data: Usage Data and various types of Data as specified in the
           privacy policy of the service
           </li>
          <li><strong>Google Analytics</strong><br/>
         Personal Data: Cookies and Usage Data
           </li>
           
              <li><strong>Flurry Analytics</strong><br/>
         Personal Data: Cookies, Usage Data and various types of Data as specified in
         the privacy policy of the service
           </li>
          </ul>
          
          
           <h2 className="terms-marg">Contacting the User</h2>
         <ul className="float-none mb-3 ne-lite-new-privacy">
          <li>Mailing list or newsletter</li>
          <li>Personal Data: email address, first name and Usage Data</li>
           </ul>
         
           <h2 className="terms-marg">Content performance and features testing (A/B testing)</h2>
         <ul class="float-none mb-3 ne-lite-new-privacy">
          <li><strong>Firebase Remote Config</strong><br/>
           Personal Data: various types of Data as specified in the privacy policy of the service
           </li>
           </ul>
           
            <h2 className="terms-marg">Infrastructure monitoring</h2>
         <ul className="float-none mb-3 ne-lite-new-privacy">
          <li><strong>Firebase Crash Reporting</strong><br/>
           Personal Data: various types of Data as specified in the privacy policy of the service
           </li>
            <li><strong>Crashlytics</strong><br/>
           Personal Data: geographic position, unique device identifiers for advertising
           (Google Advertiser ID or IDFA, for example) 
           and various types of Data as specified in the privacy policy of the service
           </li>
           </ul>
           
            <h2 className="terms-marg">Managing contacts and sending messages</h2>
         <ul className="float-none mb-3 ne-lite-new-privacy">
          <li><strong>OneSignal</strong><br/>
           Personal Data: Cookies, email address, geographic position, language, unique device identifiers 
           for advertising (Google Advertiser ID or IDFA, for example), Usage Data and various types of
           Data as specified in the privacy policy of the service
           </li>
            </ul>
            
            
               <h2 className="terms-marg">Registration and authentication</h2>
         <ul className="float-none mb-3 ne-lite-new-privacy">
          <li><strong>Facebook Authentication</strong><br/>
           Personal Data: various types of Data as specified in the privacy policy of the service
           </li>
           <li><strong>Direct registration</strong><br/>
           Personal Data: email address, first name, last name, birthday.
           </li>
            </ul>
         
         
         
         <h2 className="terms-marg">Enabling you to communicate with other users</h2>
         <p>Certain parts of our websites and online services enable you to communicate and interact 
         with other users. We will use information you have provided us to enable these interactions
         and communications on our websites and online services.</p>
         <p className="privacy-mar15p">Our lawful basis for this use of your data is: performing our contract with you</p>
         
         <h2 className="terms-marg">Processing payments</h2>
         <p>We will use your information to process payments for our goods and services.</p>
         
         <p className="privacy-mar15p">Our lawful basis for this use of your data is: performing our contract with you.</p>
        
         
         <h2 className="terms-marg">Targeted advertising</h2>
         <p>Through cookies, clear GIFs, web beacons and tracking pixels we will use your
         information to deliver relevant advertisements and offers to you and measure 
         the effectiveness of the advertising and offers. These technologies connect 
         your behaviour across different websites, mobile apps and devices and enable 
         tailored advertising to be served to your game, web browser, or mobile device.

</p>
         
         <p className="privacy-mar15p">Our lawful basis for this use of your data is: your consent.</p>
         
         <h2 className="terms-marg">Social media</h2>
         <p>We will use your information to communicate with you where you message us, 
         respond to our posts, “like” our posts, tweet or retweet us or otherwise 
         interact with us directly on social media platforms.</p>
         <p className="privacy-mar15p">Our lawful bases for this use of your data are: our legitimate 
         interests in promoting our brand and communicating with interested individuals.</p>
         
         <h2 className="terms-marg">Anti-cheat, anti-tampering, fraud and unlawful activity detection and prevention</h2>
         <p>To enforce our rules and policies, protect our customers and business, protect
         the competitive integrity of our games, investigate, and deter against fraudulent, 
         unauthorised or illegal activity.</p>
         <p className="privacy-mar15p">Our lawful bases for this use of your data are: our legitimate interests to operate a safe 
         and lawful business or where we have a legal obligation to do so.</p>
         
         <h2 className="terms-marg">Community and customer support</h2>
         <p>Handling all enquiries or complaints, troubleshooting and solving technical issues over 
         live chat, phone, email and in-game chat, making necessary changes to our products and
         services and monitoring your participation in our forums and all parts of our services
         that allow you to publicly post information or interact with other users.</p>
         <p className="privacy-mar15p">Our lawful bases for this use of your data are: performing our contract with you, our 
         legitimate interests to provide you with customer service, our legitimate interest in providing 
         the correct products and services to our website users or to comply with our legal obligations.</p>
         
         
         <h2 className="terms-marg">Improving and developing our games, services and websites</h2>
         <p>Traffic optimisation, in-game data collection, analytics and research, including
         profiling and the use of machine learning (we sometimes use carefully selected third
         parties to do this on our behalf), cheat detection and prevention, managing landing
         pages and heat mapping our sites.</p>
         <p className="privacy-mar15p">Our lawful bases for this use of your data are: performing our contract with you and our 
         legitimate interests in learning about the types of people who are interested in our games,
         websites and services, to keep our games, online services and websites updated and relevant, 
         to develop our business and to inform our marketing strategy.</p>
         
         
         
         <h2 className="terms-marg">Contests</h2>
         <p>To process your entry into, contests, surveys or other promotional events that we 
         run from time to time, should you decide to participate in them.</p>
         <p className="privacy-mar15p">Our lawful bases for this use of your data is: your consent or contract.</p>

         <h2 className="terms-marg">Marketing</h2>
         <p>Sending you communications about our services, products and features that you have agreed to receive.</p>
       <p className="privacy-mar15p">Our lawful bases for this use of your data are: your consent or our legitimate interests 
       to provide you with marketing communications where we may lawfully do so.</p>

       <h2 className="terms-marg">Contact information</h2>
      
      <p><strong>Owner and Data Controller</strong><br/>
       NE GAMES LTD<br/>
       <strong>Owner contact email</strong>: <a href="mailto:gdpr@ne-games.com">gdpr@ne-games.com</a><br/>
       Opt-out <a href="mailto:gdpr@ne-games.com">gdpr@ne-games.com</a>

       </p>
      
      <h2 className="terms-marg">FULL POLICY</h2>
        
         <p><strong>Owner and Data Controller</strong><br/>
       NE GAMES LTD <br/>
       <strong>Owner contact email</strong>: <a href="mailto:gdpr@ne-games.com">gdpr@ne-games.com</a><br/>
       Opt-out <a href="mailto:gdpr@ne-games.com">gdpr@ne-games.com</a>

       </p>
        
        
<h2 className="terms-marg">Types of Data collected</h2>
<p>Among the types of Personal Data that this Application collects, by itself or through third parties, 
there are: Cookies, Usage Data, unique device identifiers for advertising (Google Advertiser ID or IDFA, for example),
geographic position, email address and first name.
</p>
<p className="privacy-mar15p">Complete details on each type of Personal Data collected are provided in 
the dedicated sections of this privacy policy or by specific explanation texts displayed prior to
the Data collection.</p>
<p className="privacy-mar15p">Personal Data may be freely provided by the User, or, in case of Usage Data, collected 
automatically when using this Application.</p>
<p className="privacy-mar15p">Unless specified otherwise, all Data requested by this Application is mandatory
and failure to provide this Data may make it impossible for this Application to provide its services.
In cases where this Application specifically states that some Data is not mandatory, Users are free
not to communicate this Data without consequences to the availability or the functioning of the Service.</p>
<p className="privacy-mar15p">Users who are uncertain about which Personal Data is mandatory are welcome to contact 
the Owner.
Any use of Cookies – or of other tracking tools – by this Application or by the owners of third-party services 
used by this Application serves the purpose of providing the Service required by the User, in addition to any
other purposes described in the present document and in the Cookie Policy, if available.
Users are responsible for any third-party Personal Data obtained, published or shared through this Application
and confirm that they have the third party's consent to provide the Data to the Owner.
</p>
 
 
<h2 className="terms-marg">Mode and place of processing the Data</h2>
<h2 className="privacy-hthree">Methods of processing</h2>
<p>
The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, 
or unauthorized destruction of the Data.
</p>
<p>The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures 
and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be 
accessible to certain types of persons in charge, involved with the operation of this Application 
(administration, sales, marketing, legal, system administration) or external parties 
(such as third-party technical service providers, mail carriers, hosting providers, IT companies, 
communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list
of these parties may be requested from the Owner at any time.</p>

<h2 className="privacy-hthree">Legal basis of processing</h2>
<p>The Owner may process Personal Data relating to Users if one of the following applies:</p>
<ul className="float-none mb-3 ne-lite-new-privacy">
  <li>Users have given their consent for one or more specific purposes. Note: Under some legislations the 
  Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”),
  without having to rely on consent or any other of the following legal bases. 
  This, however, does not apply, whenever the processing of Personal Data is subject 
  to European data protection law;
             </li>
 <li>provision of Data is necessary for the performance of an agreement with the User and/or for
 any pre-contractual obligations thereof;
             </li>
 <li>processing is necessary for compliance with a legal obligation to which the Owner is subject;</li>	
 <li>processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;</li>				
 <li>processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.</li>						
           </ul>
           
<p>In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, 
and in particular whether the provision of Personal Data is a statutory or contractual requirement, 
or a requirement necessary to enter into a contract.</p>					  
           
           

<h2 className="privacy-hthree">Place</h2>
<p>The Data is processed at the Owner's operating locations and in any other places where the parties involved in the processing are located.</p>
<p className="privacy-mar15p">Depending on the User's location, data transfers may involve transferring the User's Data to a country other
than their own. To find out more about the place of processing of such transferred Data, Users can check the
section containing details about the processing of Personal Data.</p>
<p>Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.</p>
<p class="privacy-mar15p">If any such transfer takes place, Users can find out more by checking the relevant sections
of this document or inquire with the Owner using the information provided in the contact section.</p>


   
<h2 className="privacy-hthree">Retention time</h2>
 <p>Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.</p>
   
   <p>Therefore:</p>
   <ul className="float-none mb-3 ne-lite-new-privacy">
  <li>Personal Data collected for purposes related to the performance of a contract between the 
  Owner and the User shall be retained until such contract has been fully performed.
             </li>
 <li>Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.
   </li>
    </ul>	 

<p className="privacy-mar15p">The Owner may be allowed to retain Personal Data for a longer period whenever the User has given 
consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be
obliged to retain Personal Data for a longer period whenever required to do so for the performance 
of a legal obligation or upon order of an authority.</p>
<p className="privacy-mar15p">Once the retention period expires, Personal Data shall be deleted. Therefore, the right to access,
the right to erasure, the right to rectification and the right to data portability cannot be enforced 
after expiration of the retention period.</p>

<h2 className="terms-marg">The purposes of processing</h2>
 <p>The Data concerning the User is collected to allow the Owner to provide its Services, as well as for the following purposes: Advertising, Content performance and features testing (A/B testing), Infrastructure monitoring, Registration and authentication, Analytics and Contacting the User.
 </p>
 <p>Users can find further detailed information about such purposes of processing and about the specific Personal Data used for each purpose in the respective sections of this document.</p>
    
    
     <h2 className="terms-marg">Detailed information on the processing of Personal Data</h2>
    <p>Personal Data is collected for the following purposes and using the following services:</p>
    <ul className="float-none mb-3 ne-lite-new-privacy">
    <li>Advertising</li>
   <li>Analytics</li>
   <li>Contacting the User</li>	
   <li>Content performance and features testing (A/B testing)</li>				
   <li>Infrastructure monitoring</li>
   <li>Managing contacts and sending messages</li>
   <li>Registration and authentication</li>	
       
           </ul>
           
      <h2 className="terms-marg">The rights of Users</h2>
    <p>Users may exercise certain rights regarding their Data processed by the Owner.
In particular, Users have the right to do the following:
</p>
    <ul className="float-none mb-3 ne-lite-new-privacy">
    <li><strong>Withdraw their consent at any time.</strong> Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.</li>
   <li><strong>Object to processing of their Data.</strong> Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.</li>
   <li><strong>Access their Data.</strong> Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.</li>	
   <li><strong>Verify and seek rectification.</strong> Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.</li>				
   <li><strong>Restrict the processing of their Data.</strong> Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.</li>
   <li><strong>Have their Personal Data deleted or otherwise removed.</strong> Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.</li>
   <li><strong>Receive their Data and have it transferred to another controller.</strong> Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.</li>	
   <li><strong>Lodge a complaint.</strong> Users have the right to bring a claim before their competent data protection authority.</li>		
           </ul>		  
           
            <h2 className="terms-marg">Details about the right to object to processing</h2>
    <p>Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.
Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.
</p>

<h2 className="terms-marg">How to exercise these rights</h2>
    <p>Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.
</p>

 <h2 className="terms-marg">Additional information about Data collection and processing</h2>
 <h3 className="privacy-hthree">Legal action</h3>
 <p>The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services.
The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.

 </p>
   
   <h3 className="privacy-hthree">Additional information about User's Personal Data</h3>
 <p>In addition to the information contained in this privacy policy, this Application may provide 
 the User with additional and contextual information concerning particular Services or the collection 
 and processing of Personal Data upon request.
 </p>
   
   
   <h3 className="privacy-hthree">Legal action</h3>
 <p>The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services.
The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.

 </p>
   
   
   <h3 className="privacy-hthree">System logs and maintenance</h3>
 <p>For operation and maintenance purposes, this Application and any third-party services
 may collect files that record interaction with this Application (System logs) use other 
 Personal Data (such as the IP Address) for this purpose.
 </p>
   
   
   <h3 className="privacy-hthree">Information not contained in this policy</h3>
 <p>More details concerning the collection or processing of Personal Data may be requested
 from the Owner at any time. Please see the contact information at the beginning of this document.
 </p>
   
   
   <h3 className="privacy-hthree">How “Do Not Track” requests are handled</h3>
 <p>This Application does not support “Do Not Track” requests.
To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.

 </p>
 
 <h3 className="privacy-hthree">Changes to this privacy policy</h3>
 <p>The Owner reserves the right to make changes to this privacy policy at any time by
 giving notice to its Users on this page and possibly within this Application and/or - as 
 far as technically and legally feasible - sending a notice to Users via any contact 
 information available to the Owner. It is strongly recommended to check this page often,
 referring to the date of the last modification listed at the bottom.
 </p>
 <p className="privacy-mar15p">Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required.</p>
   
   
   
      <h2 className="terms-marg">Definitions and legal references</h2>
      <h3 className="privacy-hthree">Personal Data (or Data)</h3>
    <p>Any information that directly, indirectly, or in connection with other information — including a 
    personal identification number — allows for the identification or identifiability of a natural person.
             </p>
     
       <h3 className="privacy-hthree">Usage Data</h3>
     <p>Information collected automatically through this Application (or third-party services employed
     in this Application), which can include: the IP addresses or domain names of the computers utilized
     by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of
     the request, the method utilized to submit the request to the server, the size of the file received
     in response, the numerical code indicating the status of the server's answer (successful outcome, 
     error, etc.), the country of origin, the features of the browser and the operating system utilized
     by the User, the various time details per visit (e.g., the time spent on each page within 
     the Application) and the details about the path followed within the Application with special
     reference to the sequence of pages visited, and other parameters about the device operating 
     system and/or the User's IT environment.</p>
     
     <h3 className="privacy-hthree">User</h3>
     <p>The individual using this Application who, unless otherwise specified, coincides with the Data Subject.</p>
     
     
       <h3 className="privacy-hthree">Data Subject</h3>
     <p>The natural person to whom the Personal Data refers.</p>
   
   
     <h3 className="privacy-hthree">Data Processor (or Data Supervisor)</h3>
     <p>The natural or legal person, public authority, agency or other body which processes Personal
     Data on behalf of
     the Controller, as described in this privacy policy.</p>
     
     <h3 className="privacy-hthree">Data Controller (or Owner)</h3>
     <p>The natural or legal person, public authority, agency or other body which, alone or jointly
     with others, determines the purposes and means of the processing of Personal Data, including 
     the security measures concerning the operation and use of this Application. The Data Controller,
     unless otherwise specified, is the Owner of this Application.</p>
     
     <h3 className="privacy-hthree">This Application</h3>
     <p>The means by which the Personal Data of the User is collected and processed.</p>
     
     <h3 className="privacy-hthree">Service</h3>
     <p>The service provided by this Application as described in the relative terms (if available) and on this site/application.</p>
      
     <h3 className="privacy-hthree">European Union (or EU)</h3>
     <p>Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.</p>
      
      <h3 className="privacy-hthree">Cookies</h3>
     <p>Small sets of data stored in the User's device.</p>
      
       <h3 className="privacy-hthree">Legal information</h3>
     <p>This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).</p>
      
      <br/>
      <h3 className="privacy-hthree"> This privacy policy relates solely to this Application, if not stated otherwise within this document.</h3>
      
      
      </div>
      </div>
        
      </div>
    </>
  )
}
