import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Menubar from "./Menubar";
import Sound from "./Sound";

export default function ForGroups(props) {
  const [gameinfo, setgameinfostate] = useState(false);
  const history = useHistory();

  const [settingshow, setsettingshow] = useState(props.setting);
  useEffect(() => {
    let bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.className = "Dashbord";
  });

  useEffect(() => {
    setgameinfostate(true);
  }, []);

  const handleClick = () => {
    setgameinfostate(false);
    window.sessionStorage.setItem("checkreload", "1");
    window.sessionStorage.setItem("checkreloadhint", "1");
    history.goBack();
  };
  return (
    <>
      <Menubar setpop={setsettingshow} audioRef={props.audio} />

      <div className="modalmaindiv " id="welcomeDiv">
        {gameinfo ? (
          <div className="modalmaindiv2 " id="welcomeDiv1">
            <div className="modaldiv new-model-bg">
              <button
                className="text-center mb-1 close-btn close-btn-new-group "
                type="text"
                onClick={handleClick}
              >
                <span aria-hidden="true">
                  <span style={{ color: "red" }}>×</span>
                </span>
              </button>
              <div>
                <b>
                  AN IDEA FOR GROUPS WHO WISH
                  <br />
                  TO COMPETE AMONGST THEMSELVES
                </b>
                <br />
                <br />
                Could be a school, a school class or a family etc.
                <br />
                <br />
                Clever use of usernames enables the rankings of groups to be
                shown together, so players can see how they are performing
                within their own group,
                <br />
                <br />
                <b>For example:</b>
                <br />
                Say there is a group of players all from class 4b at St, Alban’s
                school. If the players add a set of letters after their basic
                user name, then when they search for that group of letters, only
                their rankings will be shown.
                <br />
                <br />
                <b>So usernames would be something like:</b> <br />
                MaryA_sta4b
                <br />
                JohnD_sta4b
                <br />
                HarryP_sta4b
                <br />
                PeterM_sta4b
                <br />
                <br />
                <b>
                  When “sta4b” is entered into the “search for username” bar,
                  only the rankings of those usernames with “sta4b” in them will
                  shown.
                </b>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
