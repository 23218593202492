import { getSpaceUntilMaxLength } from "@testing-library/user-event/dist/utils";
import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
// import BackButton from "./BackButton";

import BackButton from "../frontComponent/BackButton";

import { DatePicker } from "@progress/kendo-react-dateinputs";
import { string } from "yup";
//  import DatePicker from 'react-datepicker'

//import DatePicker from "react-datepicker";

export default function NewRegisterGuest() {
  const dateref = useRef();
  const [loader, setloader] = useState(false);
  const [confirmpassword, setConfirmPassword] = useState("");
  const [name, setname] = useState("");
  const [username, setusername] = useState("");
  const [referralcode, setreferralcode] = useState("");
  const [dob, setdob] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);
  const [email, setemail] = useState("");
  const [color, setcolor] = useState("coral");
  const [color1, setcolor1] = useState("");
  const [error, seterror] = useState("");
  const [popshow, setpopshow] = useState(false);
  const [catcherror, setcatcherror] = useState(false);
  const [password, setPassword] = useState("");
  // const [selectDate, setSelectedDate] = useState(null)
  const [selectDate, setSelectedDate] = React.useState(null);
  const [isloggedIn, setisLoggedIn] = useState(false);
  const [validaionemail, setvalidaionemail] = useState(false);
  const [GameOverBox, setGameOverBox] = useState(false);
  const [Reqemail, setReqemail] = useState(false);
  const [Errorsbox, setErrorsbox] = useState(false);

  // const [success, setSuccess] = React.useState(false);

  useEffect(() => {
    let bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.className = "Dashbord";
  });
  useEffect(() => {
    setisLoggedIn(window.sessionStorage.getItem("token"));
  }, [isloggedIn]);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleconfirmPasswordVisibility2 = () => {
    setConfirmPasswordShown(confirmpasswordShown ? false : true);
  };

  const allowonlyletus = () => {
    if ((name > 64 && name < 91) || (name > 96 && name < 123)) {
      return true;
    }
  };
  const validateEmail = (email) => {
    var regex =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; // /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };
  const enddate = new Date(
    new Date().getFullYear() - 7,
    new Date().getMonth(),
    new Date().getDate()
  );

  useEffect(() => {
    // var value = document.getElementById("myDate").value;
    // console.log(value, "ooooooooooo");
    // setdob(value);
  });
  const validatePassword = (password) => {
    var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,15}$/; ///^(?=.*[a-z])(?=.*[A-Z]).{5,15}$/;
    return regex.test(String(password).toLowerCase());
  };
  const validateReferral = (referralcode) => {
    var regex = /^[0-9]{1,6}$/;
    return regex.test(String(referralcode).toLowerCase());
  };
  const validateName = (name) => {
    var regex = /^[a-zA-Z][a-zA-Z ]{2,}$/;
    return regex.test(String(name).toLowerCase());
  };
  const validateDob = (dob) => {
    var regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return regex.test(String(dob).toLowerCase());
  };
  const validateDate = (dob) => {
    var date_regex =
      "^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$";
    return date_regex.test(String(dob));
  };
  // const dateRegex = new RegExp('/^([0-2^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/ ')
  //const dobr = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
  const disableDates = () => {
    var today, mm, dd, yyyy;
    today = new Date();
    mm = today.getData() + 1;
    dd = today.getData() + 1;
    yyyy = today.getFullYear();
    return yyyy + "_" + mm + "_" + dd;
  };
  function afterlogin() {
    window.sessionStorage.setItem("popup", 1);
    var myHeaders = new Headers();
    myHeaders.append(
      "APPKEY",
      "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
    );
    const url2 =
      `${process.env.REACT_APP_URL}/api/user/profile?id=` +
      window.sessionStorage.getItem("id");
    const url1 =
      `${process.env.REACT_APP_URL}/api/start/match/computer?user_id=` +
      window.sessionStorage.getItem("id") +
      "&level=1";
    const dataFinder = new Promise(async (resolve, reject) => {
      await fetch(url1, {
        headers: myHeaders,
      })
        .then((response) => response.json())
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
    dataFinder.then(async (resp) => {
      debugger;

      if (resp.status === 200 && resp.message === "Success") {
        window.sessionStorage.setItem("match_id", resp.user1.match_id);
        await fetch(url2, {
          headers: myHeaders,
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === 200) {
              window.sessionStorage.setItem(
                "image",
                response.image_path + "/" + response.data.image
              );
              window.sessionStorage.setItem("hints", response.hints);
              window.sessionStorage.setItem(
                "points",
                response.currentMonth_total_points
              );
              window.sessionStorage.setItem(
                "playButtonStatus",
                response.playButtonStatus
              );
            } else {
              //  window.sessionStorage.setItem("image", require('../assets/images/profilephoto.png'));
            }
          })
          .catch((err) => {});
      } else {
        alert("Data not found");
      }
    });
  }
  async function login(user, pass) {
    console.log(user, pass, "user");
    var myHeaders = new Headers();
    console.log("ashish");
    myHeaders.append(
      "APPKEY",
      "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const url1 =
      `${process.env.REACT_APP_URL}/api/checkdevice/prelogin?username=` +
      user +
      "&password=" +
      pass;

    await fetch(url1, requestOptions)
      .then((response) => response.json())

      .then(async (response) => {
        console.log(response, "response pooja");

        // setlogdata(true);
        var myHeaders = new Headers();
        myHeaders.append(
          "APPKEY",
          "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
        );
        var formdata = new FormData();
        formdata.append("username", user);
        formdata.append("password", pass);
        formdata.append("register_type", "email");
        formdata.append("lat", "56454");
        formdata.append("lng", "5645645");
        formdata.append("device_id", "dsvdsvdsvdsvd");
        formdata.append("fcm_token", "dsvdsvdsvdsvdsvdfbgfbg");
        const url = `${process.env.REACT_APP_URL}/api/guestUser/login`;
        await fetch(url, {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        })
          .then((response) => response.json())
          .then(async (response) => {
            console.log(response, "response baby");
            if (response.status === 200) {
              // setGameOverBox(false);
              // setlogdata(false);
              window.sessionStorage.setItem("logintype", "username");
              window.sessionStorage.setItem("username", response.data.username);

              window.sessionStorage.setItem("id", response.data.id);
              window.sessionStorage.setItem(
                "createdAt",
                response.data.created_at
              );
              window.sessionStorage.setItem("music", false);
              window.sessionStorage.setItem("sound", false);
              const url2 =
                `${process.env.REACT_APP_URL}/api/user/profile?id=` +
                response.data.id;
              await fetch(url2, {
                headers: myHeaders,
              })
                .then((response) => response.json())
                .then((response) => {
                  console.log(response, "response user");
                  if (response.status === 200) {
                    window.sessionStorage.setItem(
                      "image",
                      response.image_path + "/" + response.data.image
                    );

                    window.sessionStorage.setItem("hints", response.hints);
                    window.sessionStorage.setItem(
                      "points",
                      response.currentMonth_total_points
                    );
                    window.sessionStorage.setItem(
                      "totalpoints",
                      response.total_points
                    );
                    window.sessionStorage.setItem(
                      "username",
                      response.data.username
                    );
                    window.sessionStorage.setItem(
                      "monthtopyear",
                      response.monthTop.yearMonth
                    );
                    window.sessionStorage.setItem(
                      "monthtoppoint",
                      response.monthTop.points
                    );
                    window.sessionStorage.setItem(
                      "globalposition",
                      response.global_postion
                    );
                    window.sessionStorage.setItem(
                      "national_postion",
                      response.national_postion
                    );
                    window.sessionStorage.setItem(
                      "local_postion",
                      response.local_postion
                    );
                    window.sessionStorage.setItem("navlink", "home");
                    window.sessionStorage.setItem(
                      "playButtonStatus",
                      response.playButtonStatus
                    );

                    afterlogin();
                  } else {
                  }
                });

              if (response.data.subscription_id === null) {
                window.sessionStorage.setItem("subscribe", "");
              } else {
                window.sessionStorage.setItem("subscribe", "data");
              }
              //   window.sessionStorage.setItem("token", true);
              //   setisLoggedIn(true)
              //   window.open("/Subscribeplan", "_self")
              // }else{
              window.sessionStorage.setItem("token", true);
              setisLoggedIn(true);

              window.open("/Dashboard", "_self")
              // }
            } else {
              setvalidaionemail("");
              seterror(response.error_message);
              setGameOverBox(true);
              setReqemail("");
              setErrorsbox(true);
              // setlogdata(false);
            }
          });
      })
      .catch((err) => {});

    //
  }

  async function register(e) {
    // console.log(referralcode, "kjkjk");
    e.preventDefault();

    if (username === "") {
      seterror("Guest User name is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (username.length > 25) {
      seterror("Guest Username is Not Valid");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (password === "") {
      seterror("Password is required");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (password !== "" && password.length < 5) {
      seterror("Please enter minimum 5 length password");
      setpopshow(true);
      setcolor1("#C70100");
    } else if (password.length < 5 || password.length > 16) {
      seterror("Password is Not Valid");
      setpopshow(true);
      setcolor1("#C70100");
    } else {
      console.log("hjhj");
       login(username, password);

    //   var myHeaders = new Headers();

    //   myHeaders.append(
    //     "APPKEY",
    //     "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
    //   );

    //   var requestOptions = {
    //     method: "GET",
    //     headers: myHeaders,
    //     redirect: "follow",
    //   };

    //   const url1 =
    //     `${process.env.REACT_APP_URL}/api/checkdevice/prelogin?username=` +
    //     username +
    //     "&password=" +
    //     password;

    //   await fetch(url1, requestOptions)
    //     .then((response) => response.json())
    //     .then(async (response) => {
    //       if (
    //         response.status === 200 &&
    //         response.message === "Success" &&
    //         response.data.is_login === 1
    //       ) {
    //         // setlogdata(true);
    //         var myHeaders = new Headers();
    //         myHeaders.append(
    //           "APPKEY",
    //           "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
    //         );

    //         var formdata = new FormData();
    //         formdata.append("username", username);
    //         formdata.append("password", password);
    //         formdata.append("register_type", "guest");
    //         formdata.append("lat", "56454");
    //         formdata.append("lng", "5645645");
    //         formdata.append("device_id", "dddd");
    //         formdata.append("fcm_token", "dsvdsvdsvdsvdsvdfbgfbg");
    //         const url = `${process.env.REACT_APP_URL}/api/guestUser/login
    //           `;
    //         await fetch(url, {
    //           method: "POST",
    //           headers: myHeaders,
    //           body: formdata,
    //           redirect: "follow",
    //         })
    //           .then((response) => response.json())
    //           .then(async (response) => {
    //             console.log(response, "pooja");
    //             if (response.status === 200) {
    //               setGameOverBox(false);
    //               // setlogdata(false);
    //               window.sessionStorage.setItem("logintype", "username");
    //               window.sessionStorage.setItem(
    //                 "username",
    //                 response.data.username
    //               );

    //               window.sessionStorage.setItem("id", response.data.id);
    //               window.sessionStorage.setItem(
    //                 "createdAt",
    //                 response.data.created_at
    //               );
    //               window.sessionStorage.setItem("music", false);
    //               window.sessionStorage.setItem("sound", false);

    //               const url2 =
    //                 `${process.env.REACT_APP_URL}/api/user/profile?id=` +
    //                 response.data.id;
    //               await fetch(url2, {
    //                 headers: myHeaders,
    //               })
    //                 .then((response) => response.json())
    //                 .then((response) => {
    //                   console.log(response, "jyoti");
    //                   if (response.status === 200) {
    //                     window.sessionStorage.setItem(
    //                       "image",
    //                       response.image_path + "/" + response.data.image
    //                     );
    //                     window.sessionStorage.setItem("hints", response.hints);
    //                     window.sessionStorage.setItem(
    //                       "points",
    //                       response.currentMonth_total_points
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "totalpoints",
    //                       response.total_points
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "username",
    //                       response.data.username
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "monthtopyear",
    //                       response.monthTop.yearMonth
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "monthtoppoint",
    //                       response.monthTop.points
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "globalposition",
    //                       response.global_postion
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "national_postion",
    //                       response.national_postion
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "local_postion",
    //                       response.local_postion
    //                     );
    //                     window.sessionStorage.setItem("navlink", "home");

    //                     afterlogin();
    //                   } else {
    //                   }
    //                 });

    //               if (response.data.subscription_id === null) {
    //                 window.sessionStorage.setItem("subscribe", "");
    //               } else {
    //                 window.sessionStorage.setItem("subscribe", "data");
    //               }

    //               window.sessionStorage.setItem("token", true);
    //               // setisLoggedIn(true);

    //               window.open("/Dashboard", "_self");
                  

    //             }
    //              else {
    //               setvalidaionemail("");

    //               seterror(response.error_message);
    //               setGameOverBox(true);
    //               setReqemail("");
    //               setErrorsbox(true);
    //               setlogdata(false);
    //             }
    //           });
    //       } else {
    //         // setlogdata(true);
    //         var myHeaders = new Headers();
    //         myHeaders.append(
    //           "APPKEY",
    //           "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
    //         );
    //         var formdata = new FormData();
    //         formdata.append("username", username);
    //         formdata.append("password", password);
    //         formdata.append("register_type", "email");
    //         formdata.append("lat", "56454");
    //         formdata.append("lng", "5645645");
    //         formdata.append("device_id", "dsvdsvdsvdsvd");
    //         formdata.append("fcm_token", "dsvdsvdsvdsvdsvdfbgfbg");
    //         const url = `${process.env.REACT_APP_URL}/api/guestUser/login
    //         `;
    //         await fetch(url, {
    //           method: "POST",
    //           headers: myHeaders,
    //           body: formdata,
    //           redirect: "follow",
    //         })
    //           .then((response) => response.json())
    //           .then(async (response) => {
    //             if (response.status === 200) {
    //               setGameOverBox(false);
    //               // setlogdata(false);
    //               window.sessionStorage.setItem("logintype", "username");
    //               window.sessionStorage.setItem(
    //                 "username",
    //                 response.data.username
    //               );

    //               window.sessionStorage.setItem("id", response.data.id);
    //               window.sessionStorage.setItem(
    //                 "createdAt",
    //                 response.data.created_at
    //               );
    //               window.sessionStorage.setItem("music", false);
    //               window.sessionStorage.setItem("sound", false);
    //               const url2 =
    //                 `${process.env.REACT_APP_URL}/api/user/profile?id=` +
    //                 response.data.id;
    //               await fetch(url2, {
    //                 headers: myHeaders,
    //               })
    //                 .then((response) => response.json())
    //                 .then((response) => {
    //                   if (response.status === 200) {
    //                     window.sessionStorage.setItem(
    //                       "image",
    //                       response.image_path + "/" + response.data.image
    //                     );

    //                     window.sessionStorage.setItem("hints", response.hints);
    //                     window.sessionStorage.setItem(
    //                       "points",
    //                       response.currentMonth_total_points
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "totalpoints",
    //                       response.total_points
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "username",
    //                       response.data.username
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "monthtopyear",
    //                       response.monthTop.yearMonth
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "monthtoppoint",
    //                       response.monthTop.points
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "globalposition",
    //                       response.global_postion
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "national_postion",
    //                       response.national_postion
    //                     );
    //                     window.sessionStorage.setItem(
    //                       "local_postion",
    //                       response.local_postion
    //                     );
    //                     window.sessionStorage.setItem("navlink", "home");
    //                     window.sessionStorage.setItem(
    //                       "playButtonStatus",
    //                       response.playButtonStatus
    //                     );
    //                     afterlogin();
    //                   } else {
    //                   }
    //                 });

    //               if (response.data.subscription_id === null) {
    //                 window.sessionStorage.setItem("subscribe", "");
    //               } else {
    //                 window.sessionStorage.setItem("subscribe", "data");
    //               }
    //               //   window.sessionStorage.setItem("token", true);
    //               //   setisLoggedIn(true)
    //               //   window.open("/Subscribeplan", "_self")
    //               // }else{
    //               window.sessionStorage.setItem("token", true);
    //               setisLoggedIn(true);

    //               //window.open("/Dashboard", "_self")
    //               // }
    //             } else {
    //               setvalidaionemail("");
    //               seterror(response.error_message);
    //               setGameOverBox(true);
    //               setReqemail("");
    //               setErrorsbox(true);
    //               // setlogdata(false);
    //             }
    //           });
    //       }
    //     })
    //     .catch((err) => {});
     }

    //  else {
    //   setloader(true);
    //   var myHeaders = new Headers();
    //   myHeaders.append(
    //     "APPKEY",
    //     "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
    //   );

    //   var formdata = new FormData();

    //   formdata.append("username", username);

    //   formdata.append("device_type", "web");
    //   formdata.append("register_type", "guest");

    //   formdata.append("password", password);

    //   const url = `${process.env.REACT_APP_URL}/api/guestUser/login`;

    //   await fetch(url, {
    //     method: "POST",
    //     headers: myHeaders,
    //     body: formdata,
    //     redirect: "follow",
    //   })
    //     .then((response) => response.json())
    //     .then(async (response) => {
    //       console.log(response, "response hhhh ji");
    //       if (response.status === 200 && response.message === "Success") {
    //         setloader(false);
    //         const url3 = `${process.env.REACT_APP_URL}/api/buyHints`;

    //         var myHeaders = new Headers();
    //         myHeaders.append(
    //           "APPKEY",
    //           "Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy"
    //         );

    //         var formdata = new FormData();
    //         formdata.append("user_id", response.data.id);
    //         formdata.append("count", "5");
    //         formdata.append("amount", "0000");
    //         formdata.append("transaction_id", "0000");

    //         await fetch(url3, {
    //           method: "POST",
    //           headers: myHeaders,
    //           body: formdata,
    //           redirect: "follow",
    //         })
    //           .then((resp) => resp.json())
    //           .then(async (resp) => {
    //             if (resp.status === 200 && resp.message === "Success") {
    //             }
    //           });
    //         seterror(response.success_message);
    //         setpopshow(true);
    //         setcolor1("#00c767");
    //       } else {
    //         console.log(response);
    //         setloader(false);
    //         if (response.error_message.email === undefined) {
    //           seterror(response.error_message.username[0]);
    //           setpopshow(true);
    //           setcolor1("#C70100");
    //         } else if (response.error_message.username === undefined) {
    //           seterror(response.error_message.email[0]);
    //           setpopshow(true);
    //           setcolor("#C70100");
    //         } else {
    //           seterror(response.error_message.username[0]);
    //           setpopshow(true);
    //           setcolor1("#C70100");
    //         }
    //       }
    //       window.sessionStorage.setItem("token", true);
    //       setisLoggedIn(true);
    //       window.open("/Dashboard", "_self");
    //     })
    //     .catch((err) => {
    //       // setcatcherror(true);
    //       //  seterror("Please check all Field");
    //       // setcolor1("#C70100");
    //     });
    // }
  }

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        document.getElementById("submit-register").click();
      }
    });
  }, []);

  return (
    <div className="Newbannerbox">
      <BackButton />
      {loader ? (
        <div className="loader-wrapper1" id="loaderbox">
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      {catcherror ? (
        <>
          <div className="modalmaindiv" id="welcomeDiv">
            <div className="modaldiv">
              <div className=" cardbox " style={{ backgroundColor: color1 }}>
                <div className="eorriconbox">
                  <i className="fa fa-exclamation-triangle"></i>
                </div>

                <br />
                <p className="text-center text-white">{error}</p>
                <br />
                <div className="mainerrorclosebtn">
                  <div
                    className="errorclosebtn "
                    onClick={() => {
                      setpopshow(false);
                      window.location.reload();
                    }}
                  >
                    <p>OK</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
         <div className="formbox">
          <div></div>
          <div className="login-bg-design">
            <div className="container-fluid">
              <div className="formbox">
                <div className="formboxs" style={{ margin: "10px" }}>
                  {/* <p
                    class="register-font marb10pr"
                    style={{ display: "inline" }}
                  >
                    <b>Register</b>
                  </p> */}
                  &nbsp;
                  <p
                    class="register-font marb10pr"
                    style={{ display: "inline", fontSize: "15.4px" }}
                  >
                    <h3 className="text-center">
                <b> Login to Ne</b>-<i>Lite</i>
              </h3>
                  </p>
                  <p className="text-center login-get-started">
                <b>Get Started</b>
              </p>
                  <form className="centerbox">
                    <div className="row mart30pr">
                      {/* <div className="col-md-6 ">
                        <div className="texinput">
                          <input
                            type="text"
                            name="name"
                            placeholder="Full name"
                            value={name}
                            onChange={(e) => {
                              setname(
                                e.target.value.length < 26
                                  ? e.target.value
                                  : name
                              );
                            }}
                            onKeyPress={(event) => {
                              if (!/[a-zA-Z ]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <span className="starbox">
                            <i className="fas fa-star"></i>
                          </span>
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        {/* <input type="text" name="username" placeholder='User Name' max="5" value={username.toLowerCase()} onChange={(e) => { setusername(e.target.value.length <26 ? e.target.value : username) }} /><br /> */}
                        <input
                          type="text"
                          name="username"
                          placeholder=" Enter guest username"
                          value={username.toLowerCase()}
                          onChange={(e) => {
                            setusername(
                              e.target.value.length < 26
                                ? e.target.value
                                : username
                            );
                          }}
                          onKeyPress={(event) => {
                            if (/[, ,]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <span className="starbox">
                          <i className="fas fa-star"></i>
                        </span>
                      </div>
                    </div>

                    <div className="row "></div>
                    <div className="row"></div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="texinput">
                          <input
                            className="pass-p-absolute"
                            type={passwordShown ? "text" : "password"}
                            name="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => {
                              setPassword(
                                e.target.value.length < 16
                                  ? e.target.value
                                  : password
                              );
                            }}
                          />
                          <i
                            className="far fa-eye field-icon"
                            style={{
                              color: "white",
                              position: "absolute",
                              right: "0rem",
                              cursor: "pointer",
                            }}
                            id="togglePassword"
                            onClick={togglePasswordVisibility}
                          ></i>
                          <span className="starbox martpass7p"></span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row">
  <div className="col-md-12">
  <textarea type="text " name="bio" placeholder='Bio' value={bio} onChange={(e)=>{setbio(e.target.value)}}></textarea>

  </div>
 
</div> */}

                    <div className="row">
                      <div className="centered regis-btn-mart40p">
                        <Button
                          className="btnbox text-center mb-3"
                          style={{ backgroundColor: color }}
                          id="submit-register"
                          onClick={register}
                        >
                          login
                        </Button>
                      </div>
                    </div>
                  </form>
                  {/* <p className="text-center">
                    <Link to="/NewRegisterGuest">
                      New user? click here for sign Up
                    </Link>
                  </p> */}
                   <p className="text-center"
                    class="new-login"
                    style={{
                      marginTop: "6px",
                      marginbottom: "10px",
                      textAlign: "center",
                      fontSize: "15px",
                    }}
                  >
                    New User? Click here for 
                    <Link
                      className="sign-ip-new"
                      style={{ color: "#001628" }}
                      to="/NewRegisterGuest"
                      // onClick={regis}
                    >
                      <b> Sign up guest user</b>
                    </Link>
                    <br />
                    {/* <Link
                      to="/Support"
                      className="click-here-support"
                      onClick={regis}
                    >
                      Click here for Support
                    </Link> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
          {popshow ? (
            <>
              <div className="modalmaindiv" id="welcomeDiv">
                <div className="modaldiv">
                  <div
                    className=" cardbox "
                    style={{ backgroundColor: color1 }}
                  >
                    {color1 === "#C70100" ? (
                      <>
                        <div className="eorriconbox">
                          <i className="fa fa-exclamation-triangle"></i>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="eorriconbox">
                          <i className="fa fa-badge-check"></i>
                        </div>
                      </>
                    )}

                    <p className="text-center text-white">{error}</p>

                    <div className="mainerrorclosebtn">
                      {color1 === "#C70100" ? (
                        <>
                          <div
                            className="errorclosebtn "
                            id="close-pop"
                            onClick={() => {
                              setpopshow(false);
                            }}
                          >
                            <p>OK</p>
                          </div>
                        </>
                      ) : (
                        <Link to="/" style={{ textDecoration: "none" }}>
                          <div
                            className="errorclosebtn1 "
                            onClick={() => {
                              setpopshow(false);
                            }}
                          >
                            <p>OK</p>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}
