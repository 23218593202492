import React, { useEffect, useState,useRef } from 'react';
import Header from "./Header"
// import Footer from "./Footer"
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
export default function Register() {
  const dateref = useRef()
  const [loader, setloader] = useState(false)
  const [confirmpassword, setConfirmPassword] = useState("")
  const [name, setname] = useState("")
  const [username, setusername] = useState("")
  const [referralcode, setreferralcode] = useState("")
  const [dob, setdob] = useState("")
 
  const [email, setemail] = useState("")
  const [color, setcolor] = useState("coral")
  const [color1, setcolor1] = useState("")
  const [error, seterror] = useState("")
  const [popshow, setpopshow] = useState(false)
  const [catcherror, setcatcherror] = useState(false)
  const [password, setPassword] = useState("")
  useEffect(() => {
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = "Dashbord";
  })
  const validateEmail = (email) => {
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }
  async function register() {

    if (name === "") {
      seterror("Name is required")
      setpopshow(true)
      setcolor1("#C70100")
    } else if (username === "") {
      seterror("User name is required")
      setpopshow(true)
      setcolor1("#C70100")
    } else if (dob === "") {
      seterror("Date of Birth is required")
      setpopshow(true)
      setcolor1("#C70100")
      // }else if(country === ""){
      //   seterror("Country is required")
      //       setpopshow(true)
      //       setcolor1("#C70100")
      // }else if(town === ""){
      //   seterror("Town is required")
      //       setpopshow(true)
      //       setcolor1("#C70100")
      // }else if(state === ""){
      //   seterror("State is required")
      //       setpopshow(true)
      //       setcolor1("#C70100")
    } else if (email === "") {
      seterror("Email is required")
      setpopshow(true)
      setcolor1("#C70100")
    } else if (email !== "" && !validateEmail(email)) {
      seterror("Email is Not Valid")
      setpopshow(true)
      setcolor1("#C70100")

    } else if (password === "") {
      seterror("Password is required")
      setpopshow(true)
      setcolor1("#C70100")
    } else if (password !== confirmpassword) {
      seterror("Password Not Matched")
      setpopshow(true)
      setcolor1("#C70100")
    }
    else {
      setloader(true)
      var myHeaders = new Headers();
      myHeaders.append(
        'APPKEY',
        'Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy'
      );

      var formdata = new FormData();
      formdata.append('name', name);
      formdata.append("username", username)
      formdata.append("register_type", "email")
      formdata.append("email", email)

      formdata.append("dob", "" + dob)

      formdata.append("password", password)



      const url = `${process.env.REACT_APP_URL}/api/user/register`


      await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
      }).then((response) => response.json())
        .then(async (response) => {
          if (response.status === 200 && response.message === "Success") {
            setloader(false)
            const url3 = `${process.env.REACT_APP_URL}/api/buyHints`

            var myHeaders = new Headers();
            myHeaders.append(
              'APPKEY',
              'Py9YJXgBecbbqxjRVaHarcSnJyuzhxGqJTkY6xKZRfrdXFy72HPXvFRvfEjy'
            );
            var formdata = new FormData();
            formdata.append("user_id", response.data.id);
            formdata.append("count", "20")
            formdata.append("amount", "0000")
            formdata.append("transaction_id", "0000");

            await fetch(url3, {
              method: 'POST',
              headers: myHeaders,
              body: formdata,
              redirect: 'follow',
            }).then((resp) => resp.json())
              .then(async (resp) => {

                if (resp.status === 200 && resp.message === "Success") {


                }
              })
            seterror(response.success_message)
            setpopshow(true)
            setcolor1("#00c767")

          } else {
            console.log(response)
            setloader(false)
            if (response.error_message.email === undefined) {
              seterror(response.error_message.username[0])
              setpopshow(true)
              setcolor1("#C70100")
            } else {
              seterror(response.error_message.email[0])
              setpopshow(true)
              setcolor1("#C70100")
            }




          }
        }).catch((err) => {
          setcatcherror(true)
          seterror("Please check all Field")

          setcolor1("#C70100")
        })
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        document.getElementById("submit-register").click();


      }
    });
  }, []);

  return <>
    <Header />

    {loader ? <div className="loader-wrapper1" id="loaderbox">
      <div className="loader"></div></div> : ""}
    {catcherror ? <>
      <div className='modalmaindiv' id='welcomeDiv'>
        <div className='modaldiv'>
          <div className=" cardbox " style={{ backgroundColor: color1 }} >
            <div className='eorriconbox'><i className="fa fa-exclamation-triangle"></i></div>


            <br />
            <p className='text-center text-white'>{error}</p>
            <br />
            <div className='mainerrorclosebtn'>
              <div className="errorclosebtn " onClick={() => { setpopshow(false); window.location.reload() }}><p>OK</p></div>
            </div>

          </div>
        </div>
      </div>
    </> :
      <>

        <div className='container-fluid' style={{ marginTop: "16px" }}>

          <div className='formbox'>

            <div className='formboxs '>
              <p style={{ display: "inline" }}><b>Register</b></p>&nbsp;
              <p style={{ display: "inline", fontSize: "15.4px" }} ><b>(You need to register so we can allocate you your points)</b></p>
              <form className='centerbox'>
                <div className="row">
                  <div className="col-md-6 " >
                    <div className='texinput'>
                      <input type="text" name="name" placeholder='Full Name' value={name} onChange={(e) => { setname(e.target.value) }} /><br />
                      <span className='starbox' ><i className="fas fa-star"></i></span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <input type="text" name="username" placeholder='User Name' value={username} onChange={(e) => { setusername(e.target.value) }} /><br />
                    <span className='starbox' ><i className="fas fa-star"></i></span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className='texinput'>
                      <input  type="date"   ref={dateref} name="date" onFocus={() => (dateref.current.type = "date")} placeholder='D.O.B' value={dob} onChange={(e) => { setdob(e.target.value) }}  />
                      <span className='starbox' ><i className="fas fa-star"></i></span>

                    </div>
                  </div>
                  <div className="col-md-6">
                    <input type="text" name="code" placeholder='Referral code' value={referralcode} onChange={(e) => { setreferralcode(e.target.value.length < 7 ? e.target.value : referralcode) }} /><br />

                  </div>
                </div>
                <div className='row'>
                  <div className="col-md-12">
                    <div className='texinput'>
                      <input type="text" name="username" placeholder='Enter Email' value={email} onChange={(e) => { setemail(e.target.value) }} /><br />
                      <span className='starbox' ><i className="fas fa-star"></i></span>
                    </div>

                  </div>
                </div>


                <div className="row">

                  {/* <div className="col-md-6">
  <select type="text" name="country" value={country} onChange={(e)=>{setcountry(e.target.value)}}> 
  <option value="" >Select Country</option>
    <option value="Afghanistan">Afghanistan</option>
<option value="Albania">Albania</option>
<option value="Algeria">Algeria</option>
<option value="American Samoa">American Samoa</option>
<option value="Andorra">Andorra</option>
<option value="Angola">Angola</option>
<option value="Anguilla">Anguilla</option>
<option value="Antartica">Antarctica</option>
<option value="Antigua and Barbuda">Antigua and Barbuda</option>
<option value="Argentina">Argentina</option>
<option value="Armenia">Armenia</option>
<option value="Aruba">Aruba</option>
<option value="Australia">Australia</option>
<option value="Austria">Austria</option>
<option value="Azerbaijan">Azerbaijan</option>
<option value="Bahamas">Bahamas</option>
<option value="Bahrain">Bahrain</option>
<option value="Bangladesh">Bangladesh</option>
<option value="Barbados">Barbados</option>
<option value="Belarus">Belarus</option>
<option value="Belgium">Belgium</option>
<option value="Belize">Belize</option>
<option value="Benin">Benin</option>
<option value="Bermuda">Bermuda</option>
<option value="Bhutan">Bhutan</option>
<option value="Bolivia">Bolivia</option>
<option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
<option value="Botswana">Botswana</option>
<option value="Bouvet Island">Bouvet Island</option>
<option value="Brazil">Brazil</option>
<option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
<option value="Brunei Darussalam">Brunei Darussalam</option>
<option value="Bulgaria">Bulgaria</option>
<option value="Burkina Faso">Burkina Faso</option>
<option value="Burundi">Burundi</option>
<option value="Cambodia">Cambodia</option>
<option value="Cameroon">Cameroon</option>
<option value="Canada">Canada</option>
<option value="Cape Verde">Cape Verde</option>
<option value="Cayman Islands">Cayman Islands</option>
<option value="Central African Republic">Central African Republic</option>
<option value="Chad">Chad</option>
<option value="Chile">Chile</option>
<option value="China">China</option>
<option value="Christmas Island">Christmas Island</option>
<option value="Cocos Islands">Cocos (Keeling) Islands</option>
<option value="Colombia">Colombia</option>
<option value="Comoros">Comoros</option>
<option value="Congo">Congo</option>
<option value="Congo">Congo, the Democratic Republic of the</option>
<option value="Cook Islands">Cook Islands</option>
<option value="Costa Rica">Costa Rica</option>
<option value="Cota D'Ivoire">Cote d'Ivoire</option>
<option value="Croatia">Croatia (Hrvatska)</option>
<option value="Cuba">Cuba</option>
<option value="Cyprus">Cyprus</option>
<option value="Czech Republic">Czech Republic</option>
<option value="Denmark">Denmark</option>
<option value="Djibouti">Djibouti</option>
<option value="Dominica">Dominica</option>
<option value="Dominican Republic">Dominican Republic</option>
<option value="East Timor">East Timor</option>
<option value="Ecuador">Ecuador</option>
<option value="Egypt">Egypt</option>
<option value="El Salvador">El Salvador</option>
<option value="Equatorial Guinea">Equatorial Guinea</option>
<option value="Eritrea">Eritrea</option>
<option value="Estonia">Estonia</option>
<option value="Ethiopia">Ethiopia</option>
<option value="Falkland Islands">Falkland Islands (Malvinas)</option>
<option value="Faroe Islands">Faroe Islands</option>
<option value="Fiji">Fiji</option>
<option value="Finland">Finland</option>
<option value="France">France</option>
<option value="France Metropolitan">France, Metropolitan</option>
<option value="French Guiana">French Guiana</option>
<option value="French Polynesia">French Polynesia</option>
<option value="French Southern Territories">French Southern Territories</option>
<option value="Gabon">Gabon</option>
<option value="Gambia">Gambia</option>
<option value="Georgia">Georgia</option>
<option value="Germany">Germany</option>
<option value="Ghana">Ghana</option>
<option value="Gibraltar">Gibraltar</option>
<option value="Greece">Greece</option>
<option value="Greenland">Greenland</option>
<option value="Grenada">Grenada</option>
<option value="Guadeloupe">Guadeloupe</option>
<option value="Guam">Guam</option>
<option value="Guatemala">Guatemala</option>
<option value="Guinea">Guinea</option>
<option value="Guinea-Bissau">Guinea-Bissau</option>
<option value="Guyana">Guyana</option>
<option value="Haiti">Haiti</option>
<option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
<option value="Holy See">Holy See (Vatican City State)</option>
<option value="Honduras">Honduras</option>
<option value="Hong Kong">Hong Kong</option>
<option value="Hungary">Hungary</option>
<option value="Iceland">Iceland</option>
<option value="India">India</option>
<option value="Indonesia">Indonesia</option>
<option value="Iran">Iran (Islamic Republic of)</option>
<option value="Iraq">Iraq</option>
<option value="Ireland">Ireland</option>
<option value="Israel">Israel</option>
<option value="Italy">Italy</option>
<option value="Jamaica">Jamaica</option>
<option value="Japan">Japan</option>
<option value="Jordan">Jordan</option>
<option value="Kazakhstan">Kazakhstan</option>
<option value="Kenya">Kenya</option>
<option value="Kiribati">Kiribati</option>
<option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
<option value="Korea">Korea, Republic of</option>
<option value="Kuwait">Kuwait</option>
<option value="Kyrgyzstan">Kyrgyzstan</option>
<option value="Lao">Lao People's Democratic Republic</option>
<option value="Latvia">Latvia</option>
<option value="Lebanon" >Lebanon</option>
<option value="Lesotho">Lesotho</option>
<option value="Liberia">Liberia</option>
<option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
<option value="Liechtenstein">Liechtenstein</option>
<option value="Lithuania">Lithuania</option>
<option value="Luxembourg">Luxembourg</option>
<option value="Macau">Macau</option>
<option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
<option value="Madagascar">Madagascar</option>
<option value="Malawi">Malawi</option>
<option value="Malaysia">Malaysia</option>
<option value="Maldives">Maldives</option>
<option value="Mali">Mali</option>
<option value="Malta">Malta</option>
<option value="Marshall Islands">Marshall Islands</option>
<option value="Martinique">Martinique</option>
<option value="Mauritania">Mauritania</option>
<option value="Mauritius">Mauritius</option>
<option value="Mayotte">Mayotte</option>
<option value="Mexico">Mexico</option>
<option value="Micronesia">Micronesia, Federated States of</option>
<option value="Moldova">Moldova, Republic of</option>
<option value="Monaco">Monaco</option>
<option value="Mongolia">Mongolia</option>
<option value="Montserrat">Montserrat</option>
<option value="Morocco">Morocco</option>
<option value="Mozambique">Mozambique</option>
<option value="Myanmar">Myanmar</option>
<option value="Namibia">Namibia</option>
<option value="Nauru">Nauru</option>
<option value="Nepal">Nepal</option>
<option value="Netherlands">Netherlands</option>
<option value="Netherlands Antilles">Netherlands Antilles</option>
<option value="New Caledonia">New Caledonia</option>
<option value="New Zealand">New Zealand</option>
<option value="Nicaragua">Nicaragua</option>
<option value="Niger">Niger</option>
<option value="Nigeria">Nigeria</option>
<option value="Niue">Niue</option>
<option value="Norfolk Island">Norfolk Island</option>
<option value="Northern Mariana Islands">Northern Mariana Islands</option>
<option value="Norway">Norway</option>
<option value="Oman">Oman</option>
<option value="Pakistan">Pakistan</option>
<option value="Palau">Palau</option>
<option value="Panama">Panama</option>
<option value="Papua New Guinea">Papua New Guinea</option>
<option value="Paraguay">Paraguay</option>
<option value="Peru">Peru</option>
<option value="Philippines">Philippines</option>
<option value="Pitcairn">Pitcairn</option>
<option value="Poland">Poland</option>
<option value="Portugal">Portugal</option>
<option value="Puerto Rico">Puerto Rico</option>
<option value="Qatar">Qatar</option>
<option value="Reunion">Reunion</option>
<option value="Romania">Romania</option>
<option value="Russia">Russian Federation</option>
<option value="Rwanda">Rwanda</option>
<option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option> 
<option value="Saint LUCIA">Saint LUCIA</option>
<option value="Saint Vincent">Saint Vincent and the Grenadines</option>
<option value="Samoa">Samoa</option>
<option value="San Marino">San Marino</option>
<option value="Sao Tome and Principe">Sao Tome and Principe</option> 
<option value="Saudi Arabia">Saudi Arabia</option>
<option value="Senegal">Senegal</option>
<option value="Seychelles">Seychelles</option>
<option value="Sierra">Sierra Leone</option>
<option value="Singapore">Singapore</option>
<option value="Slovakia">Slovakia (Slovak Republic)</option>
<option value="Slovenia">Slovenia</option>
<option value="Solomon Islands">Solomon Islands</option>
<option value="Somalia">Somalia</option>
<option value="South Africa">South Africa</option>
<option value="South Georgia">South Georgia and the South Sandwich Islands</option>
<option value="Span">Spain</option>
<option value="SriLanka">Sri Lanka</option>
<option value="St. Helena">St. Helena</option>
<option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
<option value="Sudan">Sudan</option>
<option value="Suriname">Suriname</option>
<option value="Svalbard">Svalbard and Jan Mayen Islands</option>
<option value="Swaziland">Swaziland</option>
<option value="Sweden">Sweden</option>
<option value="Switzerland">Switzerland</option>
<option value="Syria">Syrian Arab Republic</option>
<option value="Taiwan">Taiwan, Province of China</option>
<option value="Tajikistan">Tajikistan</option>
<option value="Tanzania">Tanzania, United Republic of</option>
<option value="Thailand">Thailand</option>
<option value="Togo">Togo</option>
<option value="Tokelau">Tokelau</option>
<option value="Tonga">Tonga</option>
<option value="Trinidad and Tobago">Trinidad and Tobago</option>
<option value="Tunisia">Tunisia</option>
<option value="Turkey">Turkey</option>
<option value="Turkmenistan">Turkmenistan</option>
<option value="Turks and Caicos">Turks and Caicos Islands</option>
<option value="Tuvalu">Tuvalu</option>
<option value="Uganda">Uganda</option>
<option value="Ukraine">Ukraine</option>
<option value="United Arab Emirates">United Arab Emirates</option>
<option value="United Kingdom">United Kingdom</option>
<option value="United States">United States</option>
<option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
<option value="Uruguay">Uruguay</option>
<option value="Uzbekistan">Uzbekistan</option>
<option value="Vanuatu">Vanuatu</option>
<option value="Venezuela">Venezuela</option>
<option value="Vietnam">Viet Nam</option>
<option value="Virgin Islands (British)">Virgin Islands (British)</option>
<option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
<option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
<option value="Western Sahara">Western Sahara</option>
<option value="Yemen">Yemen</option>
<option value="Serbia">Serbia</option>
<option value="Zambia">Zambia</option>
<option value="Zimbabwe">Zimbabwe</option>
</select>
  </div> */}
                </div>

                {/* <div className="row">
  <div className="col-md-6">
  <div className='texinput'>
<input type="text" name="town" placeholder='Town' value={town} onChange={(e)=>{settown(e.target.value)}}/>
<span className='starbox' ><i className="fas fa-star"></i></span>
<br/>
</div>
  </div>
  <div className="col-md-6">
  <div className='texinput'>
  <input type="text" name="state" placeholder='State/CountryProvince' value={state} onChange={(e)=>{setstate(e.target.value)}}/>
  <span className='starbox' ><i className="fas fa-star"></i></span>
  <br/>
  </div>
  </div>
</div> */}

                <div className="row">
                  <div className="col-md-6">
                    <div className='texinput'>
                      <input type="password" name="password" placeholder='Enter Password' value={password} onChange={(e) => { setPassword(e.target.value) }} />
                      <span className='starbox' ><i className="fas fa-star"></i></span>
                      <br />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className='texinput'>
                      <input type="text" name="password" placeholder='Enter Confirm Password' value={confirmpassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />
                      <span className='starbox' ><i className="fas fa-star"></i></span>
                      <br />
                    </div>
                  </div>
                </div>

                {/* <div className="row">
  <div className="col-md-12">
  <textarea type="text" name="bio" placeholder='Bio' value={bio} onChange={(e)=>{setbio(e.target.value)}}></textarea>

  </div>
 
</div> */}




                <div className='centered' >
                  <Button className='  btnbox text-center mb-3' style={{ backgroundColor: color }} id="submit-register" onClick={register}>Register</Button>

                  <br />




                </div>
              </form>
              <Link to="/"><p className='text-center'><b>Click to Home</b></p></Link>
            </div>

          </div>

        </div>
        {popshow ? <>
          <div className='modalmaindiv' id='welcomeDiv'>
            <div className='modaldiv'>
              <div className=" cardbox " style={{ backgroundColor: color1 }} >
                {color1 === "#C70100" ? <><div className='eorriconbox'><i className="fa fa-exclamation-triangle"></i></div></> : <><div className='eorriconbox'><i className="fa fa-badge-check"></i></div></>}



                <br />
                <p className='text-center text-white'>{error}</p>
                <br />
                <div className='mainerrorclosebtn'>
                  {color1 === "#C70100" ? <><div className="errorclosebtn " id="close-pop" onClick={() => { setpopshow(false) }}><p>OK</p></div></> :
                    <Link to="/" style={{ textDecoration: "none" }}><div className="errorclosebtn1 " onClick={() => { setpopshow(false) }}><p>OK</p></div></Link>}

                </div>

              </div>
            </div>
          </div>
        </> : ""}
      </>}
    <br />
    {/* <Footer/> */}
  </>;

}
